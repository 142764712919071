<template>
  <ProfileBattlesList v-if="isInitLoaded" v-bind="props" @load-more="$emit('loadMore')" />
  <section v-else class="profile-battles-list_skeleton">
    <div v-for="i in 10" :key="i" class="profile-battles-list_skeleton_battle-item">
      <SkeletonBaseForeground :width="80" :height="80" :border-radius="40">
        <SkeletonBaseBackground :width="64" :height="64" :border-radius="32" />
      </SkeletonBaseForeground>
      <div class="profile-battles-list_skeleton_battle-item_cases">
        <SkeletonBaseForeground v-for="j in 4" :key="j" :width="112" :height="112" :border-radius="56" />
      </div>
      <div class="profile-battles-list_skeleton_battle-item_info">
        <div class="profile-battles-list_skeleton_battle-item_info_price">
          <SkeletonBaseForeground :width="54" :height="12" :border-radius="4" />
        </div>
        <div class="profile-battles-list_skeleton_battle-item_info_total-sell">
          <SkeletonBaseForeground :width="104" :height="32" :border-radius="6" />
          <SkeletonBaseForeground :width="54" :height="12" :border-radius="4" />
        </div>
        <div class="profile-battles-list_skeleton_battle-item_info_actions">
          <SkeletonBaseForeground :width="160" :height="40" :border-radius="8" />
          <SkeletonBaseForeground :width="160" :height="40" :border-radius="8" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { IBattlesListSkeletonProps, IBattlesListSkeletonEmits } from './BattlesList.types';

const props = defineProps<IBattlesListSkeletonProps>();
defineEmits<IBattlesListSkeletonEmits>();
</script>

<style scoped src="./BattlesList.scss" lang="scss"></style>
