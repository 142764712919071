<template>
  <div class="user-info-controller" :class="isMeClasses">
    <div class="user-info-controller__content">
      <div class="user-info-controller__info">
        <div v-if="!currentUser?.isBot && isCurrentUser" class="user-info-controller__info-id">
          ID: {{ currentUser?.userId }}
        </div>
        <ProfileUserAvatarContainer
          :current-user="currentUser"
          class="user-info-controller__info-avatar"
          @open-exp-popup="handleOpenExpPopup"
        />
        <div
          class="user-info-controller__info-right"
          :class="{ 'user-info-controller__info-right--bot': currentUser?.isBot }"
        >
          <SvgoSteamIcon
            v-if="!currentUser?.isBot && currentUser?.steam?.id"
            class="user-info-controller__info-icon"
            :width="iconSize"
            :height="iconSize"
            @click="openSteamProfile()"
          />
          <SvgoBotIcon v-else-if="currentUser?.isBot" :width="iconSize" :height="iconSize" />
        </div>
      </div>
      <ProfileUserActionWrapper :is-me="isCurrentUser" :user-name="currentUser?.nickname" />
      <BattlesPopupWrapper v-model:is-open="isOpenPopup">
        <PopupInfoUserExp :timer="eventStore.eventTimerUnixTime" @on-close-click="isOpenPopup = false" />
      </BattlesPopupWrapper>
    </div>
  </div>
</template>

<script setup lang="ts">
import PopupInfoUserExp from '../../widgets/PopupInfo/PopupInfoUserExp.vue';
import { useUserStore } from '~/store/user/user.store';
import { useEventStore } from '~/store/event/event.store';
import { ProfileUserEvents } from '~/repository/amplitude/events/profile/profile-user';
import { EProfileType } from '~/repository/amplitude/types/profile/profile-common';

const changeXL = GlobalUtils.Media.changeByMedia('xl');
const iconSize = computed(() => {
  return changeXL(32, 20);
});

const userStore = useUserStore();
const { currentUser, isCurrentUser } = storeToRefs(userStore);

const eventStore = useEventStore();

const isOpenPopup = ref<boolean>(false);

const isMeClasses = computed(() => ({
  [`user-info-controller--me`]: isCurrentUser.value,
}));

const handleOpenExpPopup = () => {
  isOpenPopup.value = true;
};

const openSteamProfile = () => {
  const steamId = currentUser.value?.steam?.id;
  if (steamId) {
    const steamProfileUrl = `https://steamcommunity.com/profiles/${steamId}`;
    window.open(steamProfileUrl, '_blank');

    ProfileUserEvents.steamLinkClicked({
      'Profile Type': isCurrentUser.value ? EProfileType.PERSONAL : EProfileType.FOREIGN,
    });
  }
};
</script>

<style src="./UserInfoController.style.scss" scoped lang="scss" />
