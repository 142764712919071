import { EProfileAdditionalEvents } from '~/repository/amplitude/types/profile/profile-additional';

export class ProfileAdditionalEvents {
  static tradeLinkClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileAdditionalEvents.TRADE_LINK_CLICKED,
    });
  }

  static steamLoginClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileAdditionalEvents.STEAM_LOGIN_CLICKED,
    });
  }

  static tradeLinkInstructionClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileAdditionalEvents.TRADE_LINK_INSTRUCTION_CLICKED,
    });
  }

  static steamLoginInstructionClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileAdditionalEvents.STEAM_LOGIN_INSTRUCTION_CLICKED,
    });
  }

  static tradeLinkReceived() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileAdditionalEvents.TRADE_LINK_RECEIVED,
    });
  }

  static steamLoginReceived() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileAdditionalEvents.STEAM_LOGIN_RECEIVED,
    });
  }

  static tradeLinkSaved() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileAdditionalEvents.TRADE_LINK_SAVED,
    });
  }

  static steamLoginSaved() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileAdditionalEvents.STEAM_LOGIN_SAVED,
    });
  }
}
