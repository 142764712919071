import type { IInventory, TRarityKey } from '~/repository/modules/inventory/inventory.types';
import { EInventoryItemStatus } from '~/repository/modules/inventory/inventory.types';
import type { IItemWsBk } from '~/features/bk/types/inventoryItems.types';
import type { IUser } from '~/store/user/user.types';
import { Colors } from '~/constants/colors.constants';

export function inventoryWsTransformTo(items: IItemWsBk[], user: IUser): IInventory[] {
  const arr: IInventory[] = [];
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    function convertQualityToRarity(quality: string): TRarityKey {
      if (quality.toUpperCase() in Colors.RARITY) {
        return quality as TRarityKey;
      }
      throw new Error(`Unknown rarity quality: ${quality}`);
    }
    arr.push({
      currency: user.finance.currency,
      id: Number(item.id),
      img: item.img,
      isUltraRare: false,
      name: item.name,
      price: item.price,
      rare: convertQualityToRarity(item.quality),
      status: EInventoryItemStatus.DEFAULT,
      updateAt: item.updateAt,
    });
  }
  return arr;
}
