<script setup lang="ts">
// eslint-disable-next-line vue/prefer-import-from-vue
import { toNumber } from '@vue/shared';
import type { IInventoryExchangeItemEmits, IInventoryExchangeItemProps } from './InventoryExchangeItem.types';
import type { IInventory } from '~/repository/modules/inventory/inventory.types';

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const props = withDefaults(defineProps<IInventoryExchangeItemProps>(), {
  inventory: () => ({}) as IInventory,
  isSelected: false,
  itemToExchange: () => ({}) as IInventory,
});

const emits = defineEmits<IInventoryExchangeItemEmits>();

const { inventory, itemToExchange, isSelected } = toRefs(props);

const isSelectedState = computed<boolean>(() => {
  return isSelected.value;
});
const declareColorOfItem = (item: IInventory) => {
  return GlobalUtils.Colors.getColorsRarity((item as IInventory).rare);
};

const diffInPrice = computed(() => {
  const diff = toNumber(itemToExchange.value.price) - toNumber(inventory.value.price);
  return parseFloat(diff.toFixed(2));
});

const isPositiveDiff = computed(() => {
  return diffInPrice.value > 0;
});

const { t } = useI18n();

const diffText = computed(() => {
  return isPositiveDiff.value ? t('profile.inventoryExchange.diffTextPos') : t('profile.inventoryExchange.diffTextNeg');
});

const iconSize = computed(() => changeXL(32, 28));
</script>

<template>
  <div class="inventory-exchange-drop-item" @click="emits('selected-toggle', inventory)">
    <SharedKitDropItem
      :class="{ 'inventory-exchange-drop-item--selected': isSelectedState }"
      :name="inventory.name"
      :image="inventory.img"
      :color="declareColorOfItem(inventory)"
      :price="String(inventory.price)"
      no-overlay
    >
      <template #top-right>
        <SvgoCansellSelected v-if="isSelectedState" :width="iconSize" :height="iconSize" />
      </template>
    </SharedKitDropItem>
    <div class="inventory-exchange-drop-item__diff">
      <div
        class="inventory-exchange-drop-item__diff-quantity"
        :class="`inventory-exchange-drop-item__diff-quantity--${isPositiveDiff ? 'positive' : 'negative'}`"
      >
        <span>{{ diffInPrice }}</span>
        <UserController>
          <template #default="{ isFake }">
            <SharedPrettyCurrency :currency="inventory.currency" :is-fake="isFake" />
          </template>
        </UserController>
      </div>
      <div class="inventory-exchange-drop-item__diff-text">{{ diffText }}</div>
    </div>
  </div>
</template>

<style scoped lang="scss" src="./InventoryExchangeItem.scss" />
