import type { IProfileBattlesFilter } from './ProfilePresentation.types';
import { ProfileBattlesFilter } from './ProfilePresentation.types';
import { EProfileTabName } from '~/repository/amplitude/types/profile/profile-tabs';
import { tabsMap } from '~/features/profile/store/profilePresentation/ProfilePresentation.data';
import { useUserStore } from '~/store/user/user.store';

const changeMD = GlobalUtils.Media.changeByMedia('md');

export const useProfilePresentationStore = defineStore('profile/presentation', () => {
  /** @private */
  const isSmallScreen = computed(() => changeMD(false, true));

  const userStore = useUserStore();
  const { currentUser } = storeToRefs(userStore);

  // Почему тела этих computed одинаковые, а имена разные?
  // Потому что, они отвечают за поведение разных компонентов,
  // это лишь совпадение, что условия этих поведений — одинаковые.
  // В будущем, всё может поменяться.
  const isTabBarVisible = computed(() => !(currentTab.value === EProfileTabName.WITHDRAWAL && isSmallScreen.value));
  const isUserInfoVisible = computed(() => !(currentTab.value === EProfileTabName.WITHDRAWAL && isSmallScreen.value));
  const isSteamTradeWidgetVisible = computed(
    () => !(currentTab.value === EProfileTabName.WITHDRAWAL && isSmallScreen.value),
  );

  const currentTab = ref(EProfileTabName.INVENTORY);
  const previousTab = ref(EProfileTabName.INVENTORY);

  watch(
    () => currentUser.value?.isBot,
    () => {
      if (currentUser.value?.isBot) {
        currentTab.value = EProfileTabName.BATTLES;
      }
    },
    { immediate: true },
  );

  // eslint-disable-next-line @typescript-eslint/naming-convention
  watch(currentTab, (_, oldValue) => {
    previousTab.value = oldValue;
  });

  function activateTab(tab: EProfileTabName) {
    currentTab.value = tab;
  }

  function activatePreviousTab() {
    currentTab.value = previousTab.value;
  }

  function isTabActive(tab: EProfileTabName) {
    return currentTab.value === tab;
  }

  function resolveTabComponent(tab: EProfileTabName) {
    return tabsMap.value[tab].component;
  }

  const currentBattlesFilter = ref(ProfileBattlesFilter.ALL);

  function activateBattlesFilter(filter: IProfileBattlesFilter) {
    currentBattlesFilter.value = filter.id;
  }

  return {
    isSteamTradeWidgetVisible,
    isTabBarVisible,
    isUserInfoVisible,

    currentTab,
    previousTab,

    activateTab,
    activatePreviousTab,
    isTabActive,
    resolveTabComponent,

    currentBattlesFilter,
    activateBattlesFilter,
  };
});
