<script setup lang="ts">
import { useUserStore } from '~/store/user/user.store';
import SteamTradeWidget from '~/features/profile/widgets/SteamTradeWidget/SteamTradeWidget.vue';

const userStore = useUserStore();
const { isUserLoaded, isCurrentUser } = storeToRefs(userStore);
</script>

<template>
  <SkeletonComponentLoader>
    <section v-if="!isUserLoaded" class="steam-trade-widget-skeleton">
      <SkeletonBaseForeground class="steam-trade-widget-skeleton__button" />
      <SkeletonBaseForeground class="steam-trade-widget-skeleton__button" />
    </section>
    <SteamTradeWidget v-else-if="isCurrentUser" />
  </SkeletonComponentLoader>
</template>

<style scoped lang="scss" src="./SteamTradeWidget.skeleton.scss" />
