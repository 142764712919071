<template>
  <ProfileStatGeneral v-if="isLoaded" v-bind="props" />
  <ProfileStatBaseSkeleton v-else :icons-count="4" show-best-drop />
</template>

<script setup lang="ts">
import type { IStatGeneralSkeletonProps } from './StatGeneral.types';

const props = defineProps<IStatGeneralSkeletonProps>();
</script>
