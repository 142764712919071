<script lang="ts" setup>
import type { ISharedSwitcherProps } from './SharedSwitcher.types';
import { ESharedSwitcherSizes } from './SharedSwitcher.types';
import { FontWeights } from '~/types/SharedFont.types';

withDefaults(defineProps<ISharedSwitcherProps>(), {
  size: ESharedSwitcherSizes.MEDIUM,
});

const model = defineModel<boolean>('modelValue', {
  required: true,
});
</script>

<template>
  <label class="shared-switcher">
    <input v-model="model" type="checkbox" />
    <SharedText
      :weight="FontWeights.BOLD"
      as="span"
      class="shared-switcher__label"
      :class="[`shared-switcher__label_size_${size}`]"
    >
      <slot />
    </SharedText>
    <span
      class="shared-switcher__switch"
      :class="[
        `shared-switcher__switch_size_${size}`,
        `shared-switcher__switch_size_${size}${model ? '--active' : ''}`,
      ]"
    >
      <span
        class="shared-switcher__switch-circle"
        :class="[
          `shared-switcher__switch-circle_size_${size}`,
          `shared-switcher__switch-circle_size_${size}${model ? '--active' : ''}`,
        ]"
      />
    </span>
  </label>
</template>

<style scoped lang="scss" src="./SharedSwitcher.scss" />
