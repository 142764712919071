<template>
  <SkeletonComponentLoader>
    <SkeletonBaseBackground v-bind="containerProps">
      <div class="user-statistics_content">
        <div class="user-statistics_header">
          <SkeletonBaseForeground v-bind="headerProps" />
        </div>
        <div class="user-statistics_badges-container">
          <div v-for="i in iconsCount" :key="i" class="user-statistics-badge">
            <SkeletonBaseForeground v-bind="badgeIconProps" />
            <SkeletonBaseForeground class="user-statistics-badge_title" v-bind="badgeTitleProps" />
            <SkeletonBaseForeground v-bind="badgeValueProps" />
          </div>
          <div v-if="showBestDrop">
            <SkeletonBaseForeground v-bind="dropItemBadgeProps" />
          </div>
        </div>
      </div>
    </SkeletonBaseBackground>
  </SkeletonComponentLoader>
</template>

<script setup lang="ts">
import type { IStatBaseSkeletonProps } from './StatBase.types';

withDefaults(defineProps<IStatBaseSkeletonProps>(), {
  showBestDrop: false,
});

const changeMD = GlobalUtils.Media.changeByMedia('md');

const containerProps = computed(() => ({
  borderRadius: changeMD(12, 24),
}));

const headerProps = computed(() => ({
  borderRadius: 4,
  height: changeMD(8, 12),
  width: changeMD(112, 160),
}));

const badgeIconProps = computed(() => ({
  borderRadius: changeMD(40, 28),
  height: changeMD(40, 28),
  width: changeMD(40, 28),
}));

const badgeTitleProps = computed(() => ({
  borderRadius: 4,
  height: 8,
  width: changeMD(72, 97),
}));

const badgeValueProps = computed(() => ({
  borderRadius: 4,
  height: 16,
  width: 57,
}));

const dropItemBadgeProps = computed(() => ({
  borderRadius: 8,
  height: 84,
  width: 104,
}));
</script>

<style src="./StatBase.scss" scoped lang="scss"></style>
<style src="../StatIconBadge/StatIconBadge.scss" scoped lang="scss"></style>
