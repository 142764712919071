import type {
  ISharedButtonNewColorPreset,
  ISharedButtonNewFormPreset,
  ISharedButtonTextPreset,
} from '~/types/SharedButtonNew.types';
import {
  defaultButtonColorPreset,
  defaultMediumButtonTextPreset,
  defaultMiddleButtonFormPreset,
} from '~/components/SharedButtonNew/SharedButtonNew.data';
import type { IBaseForegroundProps } from '~/features/skeleton/components/BaseForeground/BaseForeground.types';

const changeMD = GlobalUtils.Media.changeByMedia('md');

export const InventoryListButtonFormPreset = computed<ISharedButtonNewFormPreset>(() => ({
  ...defaultMiddleButtonFormPreset,
  height: changeMD('40px', '32px'),
  minWidth: '200px',
  radius: 'var(--border-radius-xs)',
  width: '200px',
}));

export const InventoryListButtonColorPreset = computed<ISharedButtonNewColorPreset>(() => ({
  ...defaultButtonColorPreset,
  color: 'var(--neutral-white-15)',
}));

export const InventoryListButtonTextPreset = computed<ISharedButtonTextPreset>(() => ({
  ...defaultMediumButtonTextPreset,
  size: changeMD('var(--font-size-m)', 'var(--font-size-s)'),
}));

export const InventoryListSkeletonProps = <IBaseForegroundProps>{
  borderRadius: 8,
  width: 200,
  height: 40,
};
