<template>
  <div class="popup-wrapper">
    <div class="top-img-container">
      <NuxtImg class="top-img-container_top-img" provider="localProvider" :src="BgLogo" />
      <SvgoCloseXIcon class="svg-close-icon" @click="emits('onCloseClick')" />
    </div>
    <div class="text-logo-container">
      <div class="header-logo">
        <SharedTitle
          :text-props="{
            fontFamily: 'var(--reaver)',
            gradient: { colorStops: Colors.LVL.FREE_CASES, toDirection: '180deg' },
            display: 'inline-flex',
            alignItems: 'center',
            size: changeXL(FontSizes.LARGE_2XL, FontSizes.LARGE_XL),
            weight: FontWeights.BOLD,
            letterSpacing: changeXL(2.4, 2),
          }"
          hide-side-lines
        >
          {{ $t('profile.levelsGuide.title') }}
        </SharedTitle>
      </div>
      <div class="text">
        <div v-for="(item, i) in $tm('profile.levelsGuide.textItems')" :key="i" class="text-item">
          <I18nT :keypath="'profile.levelsGuide.textItems.' + i" tag="div">
            <template #freeCases>
              <span> {{ $t('profile.levelsGuide.freeCases') }}</span>
            </template>
            <template #timer>
              <span class="text-item-timer">
                <I18nT keypath="profile.levelsGuide.timer" tag="span">
                  <template #days>{{ refinedTime.days }}</template>
                  <template #hours>{{ refinedTime.hours }}</template>
                  <template #minutes>{{ refinedTime.days }}</template>
                </I18nT>
              </span>
            </template>
          </I18nT>
        </div>
      </div>
      <div class="button-container">
        <SharedKitButton
          class="button-container_button"
          :color-preset="ColorPresets.DOTA.PRIMARY"
          :size-preset="changeXL(SizePresets.L, SizePresets.M)"
          :type-preset="TypePresets.ROUND"
          :text="$t('profile.popupCloseText')"
          @click="emits('onCloseClick')"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IPopupInfoUserExpEmits, IPopupInfoUserExpProps } from './PopupInfoUserExp.types';
import { TypePresets } from '~/components/SharedIcon/SharedIcon.presets';
import { SizePresets, ColorPresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { FontSizes, FontWeights } from '~/types/SharedFont.types';
import { Colors } from '~/constants/colors.constants';
import BgLogo from '@/assets/images/Logo/popup-top-img.png';

const changeXL = GlobalUtils.Media.changeByMedia('xl');
const emits = defineEmits<IPopupInfoUserExpEmits>();
const props = defineProps<IPopupInfoUserExpProps>();
const { timer } = toRefs(props);

const { timerData, start } = useTimer(timer);

onMounted(() => {
  start();
});

const refinedTime = computed(() => {
  return {
    days: timerData.value.days,
    hours: timerData.value.hours,
    minutes: timerData.value.minutes,
  };
});
</script>

<style src="./PopupInfoUserExp.style.scss" scoped lang="scss"></style>
