<template>
  <div class="inventory-list">
    <div class="inventory-list__header">
      <InventoryActions v-if="!inventoryPending && isCurrentUser" />
      <InventoryActionsSkeleton v-else-if="inventoryPending" />
    </div>
    <template v-if="isShowEmptyInventoryWidget">
      <CaseInventoryEmptyWidget class="inventory-list__empty" />
    </template>
    <template v-else>
      <div class="inventory-list__items">
        <transition-group name="list-item">
          <template v-if="!isEmptyInventory">
            <InventoryItem v-for="item in userInventory" :key="item.id" :inventory="item" />
          </template>
          <template v-if="inventoryPending">
            <InventoryItemSkeleton v-for="i in 20" :key="i" />
          </template>
        </transition-group>
      </div>
      <SharedButtonNew
        v-if="!inventoryStore.pagination.isLastPage && !inventoryPending"
        class="inventory-list__load"
        :form="InventoryListButtonFormPreset"
        :color="InventoryListButtonColorPreset"
        @click="loadMoreHandler"
      >
        {{ $t('loadMore') }}
      </SharedButtonNew>
      <SkeletonBaseForeground
        v-else-if="inventoryPending"
        class="inventory-list__load"
        v-bind="InventoryListSkeletonProps"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { useProfilePresentationStore } from '../../store/profilePresentation/ProfilePresentation.store';
import {
  InventoryListButtonColorPreset,
  InventoryListButtonFormPreset,
  InventoryListSkeletonProps,
} from './InventoryList.data';
import InventoryActions from '~/features/profile/widgets/InventoryActions/InventoryActions.vue';
import InventoryItem from '~/features/profile/components/InventoryItem/InventoryItem.vue';
import InventoryItemSkeleton from '~/features/profile/components/InventoryItem/InventoryItem.skeleton.vue';
import CaseInventoryEmptyWidget from '~/features/cases/widgets/CaseInventoryEmptyWidget/CaseInventoryEmptyWidget.vue';
import InventoryActionsSkeleton from '~/features/profile/widgets/InventoryActions/InventoryActions.skeleton.vue';
import { useInventoryStore } from '~/store/inventory/inventory';
import { useInventoryWsStore } from '~/store/inventory/inventory.ws';
import { useUserStore } from '~/store/user/user.store';
import { ProfileTabsEvents } from '~/repository/amplitude/events/profile/profile-tabs';

const { isUserLoaded } = storeToRefs(useUserStore());

const inventoryStore = useInventoryStore();
const { userInventory, inventoryPending, isCurrentUser } = storeToRefs(inventoryStore);

const inventoryWsStore = useInventoryWsStore();

const profilePresentationStore = useProfilePresentationStore();

const isEmptyInventory = computed(() => !userInventory.value.length && !inventoryPending.value);

onMounted(() => {
  inventoryWsStore.connectWsStateChange();
  inventoryWsStore.connectWsItemResponse();

  if (isUserLoaded.value) {
    inventoryStore.refreshInventory();
  }
});

const isShowEmptyInventoryWidget = computed(() => {
  return isCurrentUser.value && isEmptyInventory.value;
});

const loadMoreHandler = () => {
  ProfileTabsEvents.loadMoreClicked({
    'Tab Name': profilePresentationStore.currentTab,
  });

  inventoryStore.pagination.loadMore();
};

onUnmounted(() => {
  inventoryStore.pagination.currentPage = 1;
  userInventory.value = [];
});
</script>

<style lang="scss" src="./InventoryList.style.scss" scoped></style>
