<template>
  <section
    class="shared-slider"
    @mousedown="startDrag"
    @touchstart="startDrag"
    @mouseup="stopDrag"
    @touchend="stopDrag"
  >
    <div v-if="changeXL(showButtons, false)" class="shared-slider__controls">
      <div v-if="showPrevButton" class="shared-slider__controls-prev" @click="currentStep--">
        <SvgoSliderPrevIcon />
      </div>
      <div v-if="showNextButton" class="shared-slider__controls-next" @click="currentStep++">
        <SvgoSliderNextIcon />
      </div>
    </div>
    <div class="shared-slider__body">
      <div class="shared-slider__wrapper" :style="sliderBakedStyles">
        <div
          v-for="slide in slides"
          :key="slide[slideKey] as string"
          class="shared-slider__body-item"
          :style="slideStyles"
        >
          <slot name="slide" v-bind="{ slide }" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { ISharedSliderProps } from './SharedSlider.types';

import { Gap } from '~/constants/common.constants';

const props = withDefaults(defineProps<ISharedSliderProps>(), {
  showButtons: true,
  sliderStyles: () => ({
    gap: GlobalUtils.Converting.toPx(Gap.XS2),
  }),
  slidesAmount: 1,
  slidesMobileAmount: 1,
});

const currentStep = ref(0);
const isDragging = ref(false);
const dragStart = ref(0);

const changeXL = GlobalUtils.Media.changeByMedia('xl');
const mainSlidesAmount = computed(() => changeXL(props.slidesAmount, props.slidesMobileAmount));

const stepOffset = computed(() => 1 / mainSlidesAmount.value);

// Свойства отображения кнопки вправо и влево
const showPrevButton = computed(() => currentStep.value !== 0);
const showNextButton = computed(() => {
  if (mainSlidesAmount.value === props.slides.length) return false;

  return (currentStep.value + 1) * Math.floor(mainSlidesAmount.value) < props.slides.length;
});

const sliderBakedStyles = computed(() => ({
  ...props.sliderStyles,
  cursor: isDragging.value ? 'grabbing' : 'default',
  transform: `translateX(${-100 * currentStep.value * stepOffset.value * Math.floor(mainSlidesAmount.value)}%)`,
}));

const slideStyles = computed(() => ({
  flex: `1 0 calc(${100 / mainSlidesAmount.value}% - ${props.sliderStyles.gap ?? '0px'} / 2)`,
}));

const startDrag = (e: MouseEvent | TouchEvent) => {
  isDragging.value = true;
  dragStart.value = e instanceof TouchEvent ? e.changedTouches[0]?.screenX : e.screenX;
};
const stopDrag = (e: MouseEvent | TouchEvent) => {
  isDragging.value = false;
  const endX = e instanceof TouchEvent ? e.changedTouches[0]?.screenX : e.screenX;
  const dragOffset = endX - dragStart.value;

  if (dragOffset === 0) return;

  if (dragOffset < 0 && showNextButton.value) {
    currentStep.value++;
    return;
  }

  if (dragOffset > 0 && showPrevButton.value) {
    currentStep.value--;
  }
};
</script>

<style scoped lang="scss" src="./SharedSlider.scss" />
