<template>
  <div class="user-statistics-badge">
    <Component :is="currentIconComponent" class="user-statistics-badge_icon" />
    <h2 class="user-statistics-badge_title">{{ titleNonBreakingSpaced }}</h2>
    <SharedText v-bind="gradientTextProps">
      <slot />
    </SharedText>
    <div class="user-statistics-badge_no-gradient">
      <slot name="no-gradient" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IUserStatisticsBadgeProps } from './StatIconBadge.types';
import { Colors } from '~/constants/colors.constants';
import { FontSizes, FontWeights } from '~/types/SharedFont.types';

const { snake2Camel } = GlobalUtils.Text;
const changeMD = GlobalUtils.Media.changeByMedia('md');

const props = defineProps<IUserStatisticsBadgeProps>();

const titleNonBreakingSpaced = computed(() => props.title.replace(/\s/g, '\u00A0'));

const iconsMap: Record<string, string> = {
  knives: 'SvgoProfileStatisticsKnives',
  max: 'SvgoProfileStatisticsMax',
  openCase: 'SvgoProfileStatisticsOpenCase',
  score: 'SvgoProfileStatisticsScore',
  star: 'SvgoProfileStatisticsStar',
  steam: 'SvgoProfileStatisticsSteam',
  upgrade: 'SvgoProfileStatisticsUpgrade',
  winrate: 'SvgoProfileStatisticsWinrate',
};

const currentIconComponent = iconsMap[snake2Camel(props.icon)];

const gradientTextProps = computed(() => ({
  gradient: { colorStops: [Colors.PROFILE.STATISTICS_PALE_PINK, Colors.PROFILE.STATISTICS_PINK] },
  size: changeMD(FontSizes.LARGE_XL, FontSizes.LARGE),
  weight: FontWeights.BLACK,
}));
</script>

<style src="./StatIconBadge.scss" scoped lang="scss"></style>
