<template>
  <section v-if="!isLoaded" class="withdrawal-history-table-skeleton">
    <div
      v-for="i in 20"
      :key="i"
      class="withdrawal-history-table-skeleton_row"
      :class="{ 'withdrawal-history-table-skeleton_row_alt': isEven(i) }"
    >
      <SkeletonBaseForeground
        v-for="j in 4"
        :key="j"
        :border-radius="5"
        :height="12"
        :width="100"
        class="withdrawal-history-table-skeleton_cell"
      />
    </div>
  </section>
  <ProfileWithdrawalHistoryTable v-else v-bind="props" />
</template>

<script setup lang="ts">
import type { IWithdrawalHistoryTableSkeletonProps } from './WithdrawalHistoryTable.types';

const props = defineProps<IWithdrawalHistoryTableSkeletonProps>();

function isEven(num: number) {
  return num % 2 === 0;
}
</script>

<style scoped src="./WithdrawalHistoryTable.scss" lang="scss" />
