import { EProfileUserEvents } from '~/repository/amplitude/types/profile/profile-user';
import type { IProfileTypeEvent } from '~/repository/amplitude/types/profile/profile-common';

export class ProfileUserEvents {
  static steamLinkClicked(event: IProfileTypeEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileUserEvents.STEAM_LINK_CLICKED,
      eventProperties: event,
    });
  }

  static levelInstructionClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileUserEvents.LEVEL_INSTRUCTION_CLICKED,
    });
  }

  static coinInstructionClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileUserEvents.COIN_INSTRUCTION_CLICKED,
    });
  }

  static levelGenerated() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileUserEvents.LEVEL_GENERATED,
    });
  }

  static dailyCaseClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileUserEvents.TO_DAILY_CASE_CLICKED,
    });
  }

  static dailyCaseClosed() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileUserEvents.TO_DAILY_CASE_CLOSED,
    });
  }
}
