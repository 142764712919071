<template>
  <div class="profile-battles-list_container">
    <div v-show="battles.length" class="profile-battles-list_header">
      <span>{{ $t('profile.battles.columns.rounds') }}</span>
      <span>{{ $t('profile.battles.columns.cases') }}</span>
      <span>{{ $t('profile.battles.columns.price') }}</span>
      <span>{{ $t('profile.battles.columns.players') }}</span>
      <span>{{ $t('profile.battles.columns.actions') }}</span>
    </div>
    <template v-if="battles.length">
      <div>
        <ProfileBattlesItem
          v-for="battle in battles"
          :key="battle.ulid"
          class="profile-battles-list_item"
          :battle="battle"
          :is-cancel-disabled="isCancelBattleDisabled"
          @go-to-battle="handleGoToBattle"
          @duplicate-battle="handleDuplicateBattle"
          @cancel-battle="handleCancelBattle"
        />
      </div>
      <div class="profile-battles-list_load-more">
        <SharedKitButton v-bind="loadMoreButtonProps" :is-disabled="!isMoreLoaded" @click="$emit('loadMore')">
          {{ $t('loadMore') }}
        </SharedKitButton>
      </div>
    </template>
    <div v-else class="profile-battles-list_fallback">
      <SvgoProfileBattlesKnivesOutline />
      <h1>{{ $t('profile.battles.emptyState.title') }}</h1>
      <p>{{ $t('profile.battles.emptyState.text') }}</p>
      <SharedKitButton v-bind="makeBattleButtonProps">{{
        $t('profile.battles.emptyState.buttonText')
      }}</SharedKitButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IBattlesListEmits, IBattlesListProps } from './BattlesList.types';
import { useBattlesListStore } from '~/features/battles/store/battlesList.store';
import type { IBattlesBattleShortItemInStore } from '~/features/battles/types/battlesStore.types';
import type { ISharedKitButtonProps } from '~/components/SharedKitButton/SharedKitButton.types';
import { Colors } from '~/constants/colors.constants';
import { useProfileBattlesStore } from '~/features/profile/store/profileBattles/ProfileBattles.store';
import type { IProfileBattleCase } from '~/repository/modules/profile/types/battles';

defineProps<IBattlesListProps>();
defineEmits<IBattlesListEmits>();

const router = useRouter();
const battlesListStore = useBattlesListStore();
const profileBattlesStore = useProfileBattlesStore();

function handleGoToBattle(ulid: string) {
  window.open(router.resolve(`/battles/${ulid}`).href, '_blank');
}

function handleDuplicateBattle(payload: {
  isBots: boolean;
  isPrivate: boolean;
  slots: number;
  cases: IProfileBattleCase[];
}) {
  battlesListStore.createTheSameBattle(
    {
      bots: !!payload.isBots,
      private: !!payload.isPrivate,
      slots: payload.slots,
      cases: [...payload.cases].map((caseItem) => ({ ...caseItem, id: caseItem.caseId })).reverse(),
    } as unknown as IBattlesBattleShortItemInStore,
    'profile/battles',
  );
}

const isCancelBattleDisabled = ref(false);

async function handleCancelBattle(ulid: string, price: number) {
  try {
    isCancelBattleDisabled.value = true;
    await profileBattlesStore.cancelBattle(ulid, price);
  } finally {
    isCancelBattleDisabled.value = false;
  }
}

const makeBattleButtonProps: ISharedKitButtonProps = {
  colorPreset: {
    defaultBackground: Colors.MAIN.PURPLE_200,
    hoverBackground: Colors.MAIN.PURPLE_300,
    loadingColor: Colors.MAIN.PURPLE_400,
    textColor: Colors.NEUTRAL.WHITE,
  },
  linkProps: { to: '/battles/create' },
  sizePreset: { fontSize: 10, height: 50, type: 'default' },
  typePreset: { default: 12, large: 12, medium: 12 },
};

const loadMoreButtonProps: ISharedKitButtonProps = {
  colorPreset: {
    defaultBackground: Colors.NEUTRAL.WHITE_15,
    hoverBackground: Colors.MAIN.PURPLE_200,
    loadingColor: Colors.NEUTRAL.WHITE_15,
    textColor: Colors.NEUTRAL.WHITE,
  },
  sizePreset: { fontSize: 10, height: 50, type: 'default' },
  typePreset: { default: 12, large: 12, medium: 12 },
};
</script>

<style scoped src="./BattlesList.scss" lang="scss"></style>
