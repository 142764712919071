<script setup lang="ts">
import type { IBaseForegroundProps } from '~/features/skeleton/components/BaseForeground/BaseForeground.types';

const containerProps: IBaseForegroundProps = {
  borderRadius: 8,
  width: 100,
  height: 100,
};
</script>

<template>
  <SkeletonComponentLoader>
    <section class="inventory-actions-skeleton">
      <div class="inventory-actions-skeleton__buttons">
        <SkeletonBaseForeground
          class="inventory-actions-skeleton__buttons-button inventory-actions-skeleton__buttons-button--sell"
          v-bind="containerProps"
        />
        <SkeletonBaseForeground class="inventory-actions-skeleton__buttons-button" v-bind="containerProps" />
      </div>
      <SkeletonBaseForeground class="inventory-actions-skeleton__switcher" v-bind="containerProps" />
    </section>
  </SkeletonComponentLoader>
</template>

<style scoped lang="scss" src="./InventoryActions.skeleton.scss" />
