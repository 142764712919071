<template>
  <ProfileStatBase :header="header">
    <template #badges>
      <ProfileStatIconBadge v-for="badge in badges" :key="badge.id" :title="badge.title" :icon="badge.icon">
        {{ badge.value }}
      </ProfileStatIconBadge>
    </template>
    <template #bestDrop>
      <div class="user-statistics-dropItemBadge">
        <h2 class="user-statistics-dropItemBadge_title">
          {{ $t('profile.statWidget.dropItemTitle') }}
        </h2>
        <div class="user-statistics-dropItemBadge_shadow">
          <SharedKitDropItem
            :currency="bestDropCurrency"
            :name="bestDropName!"
            :image="bestDropImage!"
            :color="bestDropColor"
            :price="bestDropPrice!"
          />
        </div>
      </div>
    </template>
  </ProfileStatBase>
</template>

<script setup lang="ts">
import { StatIcon } from '../StatIconBadge/StatIconBadge.types';
import type { IStatGeneralProps } from './StatGeneral.types';

const props = defineProps<IStatGeneralProps>();

const { t } = useI18n();

const bestDropColor = GlobalUtils.Colors.getColorsRarity(props.bestDropRarity!);

// 'rgb(1, 2, 3)' => '1, 2, 3' в стилях будет обёрнуто в rgba и добавлено значение альфа-канала
const bestDropRgbColorString = GlobalUtils.Colors.hex2Rgb(bestDropColor, 'string', 0).replace(/[a-z()]/g, '');

const header = t('profile.statWidget.headers.general');

const badges = [
  {
    icon: StatIcon.OPEN_CASE,
    id: 0,
    title: t('profile.statWidget.badges.general.inventoryCount'),
    value: props.inventoryCount,
  },
  {
    icon: StatIcon.KNIVES,
    id: 1,
    title: t('profile.statWidget.badges.general.battlesCount'),
    value: props.battlesCount,
  },
  {
    icon: StatIcon.UPGRADE,
    id: 2,
    title: t('profile.statWidget.badges.general.upgradesCount'),
    value: props.upgradesCount,
  },
  {
    icon: StatIcon.STEAM,
    id: 3,
    title: t('profile.statWidget.badges.general.sendItemsCount'),
    value: props.sendItemsCount,
  },
];
</script>

<style scoped>
.user-statistics-dropItemBadge {
  --shadow-color: v-bind(bestDropRgbColorString);
}
</style>

<style scoped src="./StatGeneral.scss" lang="scss"></style>
