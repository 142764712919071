import type { IBaseTabEventClicked } from '~/repository/amplitude/types/profile/profile-tabs';
import { EProfileTabsEvents } from '~/repository/amplitude/types/profile/profile-tabs';

export class ProfileTabsEvents {
  static tabClicked(event: IBaseTabEventClicked) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileTabsEvents.TAB_CLICKED,
      eventProperties: event,
    });
  }

  static loadMoreClicked(event: IBaseTabEventClicked) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileTabsEvents.LOAD_MORE_CLICKED,
      eventProperties: event,
    });
  }
}
