<template>
  <div v-if="authStore.isAuth" class="profile-container">
    <div class="left-part">
      <ProfileUserInfoControllerSkeleton v-show="profilePresentationStore.isUserInfoVisible" />
      <ProfileSteamTradeWidgetSkeleton v-show="profilePresentationStore.isSteamTradeWidgetVisible" />
    </div>
    <div class="right-part">
      <ProfileTabController />
    </div>
  </div>
  <div v-else>
    <div class="profile-noauth__wrapper">
      <div class="profile-noauth__image">
        <SharedImage :img="errorNoLogo" />
      </div>
      <div class="profile-noauth__content">
        <h2 class="profile-noauth__title">
          {{ t('profile.noAuthPage.noAuthTitle') }}
        </h2>
        <div class="profile-noauth__buttons">
          <SharedButtonsGroup :border-radius="changeXL(0, 8)" :direction="EButtonsGroupDirection.ROW" :gap="2">
            <SharedKitButton
              :text="$t('profile.noAuthPage.noAuthLogin')"
              :color-preset="ColorPresets.DOTA.PRIMARY"
              :size-preset="actionButtonsSize"
              :type-preset="TypePresets.NONE"
              @click="authPopupHandler"
            >
            </SharedKitButton>
            <SharedKitButton
              :link-props="{
                to: ROUTING.MAINPAGE.MAINPAGE.path,
              }"
              :text="$t('profile.noAuthPage.noAuthMain')"
              :color-preset="ColorPresets.DOTA.QUICKSALE"
              :size-preset="actionButtonsSize"
              :type-preset="TypePresets.NONE"
            >
            </SharedKitButton>
          </SharedButtonsGroup>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from '~/features/authentication/store/authorization';
import errorNoLogoImage from 'assets/images/Error/error-no-logo.png';
import type { TImageType } from '~/components/SharedImage/SharedImage.types';
import { ColorPresets, SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { useAuthPopupStore } from '~/features/general-auth/store/popup.store';
import { EButtonsGroupDirection } from '~/components/SharedButtonsGroup/SharedButtonsGroup.types';
import { useProfilePresentationStore } from '~/features/profile/store/profilePresentation/ProfilePresentation.store';

definePageMeta({
  layout: 'profile',
});

const { t } = useI18n();

const authStore = useAuthStore();
const authPopupStore = useAuthPopupStore();

const profilePresentationStore = useProfilePresentationStore();

const errorNoLogo = computed<TImageType>(() => {
  return {
    png1: errorNoLogoImage,
  };
});

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const actionButtonsSize = computed(() => changeXL(SizePresets.M, SizePresets.S));

const authPopupHandler = () => {
  authPopupStore.open();
};
</script>

<style src="./UserPageSecret.scss" scoped lang="scss"></style>
