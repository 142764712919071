import type {
  ISharedButtonNewColorPreset,
  ISharedButtonNewFormPreset,
  ISharedButtonTextPreset,
} from '~/types/SharedButtonNew.types';
import {
  defaultButtonColorPreset,
  defaultMediumButtonTextPreset,
  defaultMiddleButtonFormPreset,
} from '~/components/SharedButtonNew/SharedButtonNew.data';
import { Colors } from '~/constants/colors.constants';

const changeXL = GlobalUtils.Media.changeByMedia('xl');

export const InventoryExchangePopupHeadButtonFormPreset = computed<ISharedButtonNewFormPreset>(() => ({
  ...defaultMiddleButtonFormPreset,
  height: changeXL('50px', '40px'),
  minWidth: 'unset',
  radius: 'var(--border-radius-xs)',
  width: '100%',
}));

export const InventoryExchangePopupHeadButtonColorPreset = computed<ISharedButtonNewColorPreset>(() => ({
  ...defaultButtonColorPreset,
  color: Colors.MAIN.GOLD,
  colorHovered: Colors.NEUTRAL.WHITE,
}));

export const InventoryExchangePopupHeadButtonTextPreset = computed<ISharedButtonTextPreset>(() => ({
  ...defaultMediumButtonTextPreset,
  color: Colors.NEUTRAL.BLACK,
  size: changeXL('var(--font-size-l)', 'var(--font-size-m)'),
}));
