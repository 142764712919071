<template>
  <div
    class="drop-wrapper"
    :class="{ 'drop-wrapper__mobile': isMobile }"
    @click="emits('selected-toggle', inventory.id)"
  >
    <SharedKitDropItem
      :id="inventory.id"
      :currency="inventory.currency"
      :name="inventory.name"
      :image="inventory.image"
      :color="GlobalUtils.Colors.getColorsRarity(inventory.rare || 'ARCANA')"
      :price="inventory.price"
      class="drop-item"
      :class="classes"
      :disabled="disabled"
      :link="link"
      :shining="shining"
      :shining-color="shiningColor"
      :type="type"
    >
    </SharedKitDropItem>
    <div v-if="isMobile" class="drop-info">
      <div class="drop-info__price">{{ price }}</div>
      <div class="drop-info__name">{{ inventory.name }}</div>
    </div>

    <SvgoCansellSelected v-if="inventory.isSelected" class="drop-icon" />
  </div>
</template>

<script setup lang="ts">
import SharedKitDropItem from '../SharedKitDropItem/SharedKitDropItem.vue';
import type { IEmitsQsDropItem, IPropsQsDropItem } from './SharedKitDropItemNew.types';

const props = withDefaults(defineProps<IPropsQsDropItem>(), { isMobile: false });
const emits = defineEmits<IEmitsQsDropItem>();

const classes = computed(() => ({
  'drop-item__mobile': props.isMobile,
  'drop-item__selected': props.inventory.isSelected,
}));

const { inventory, isMobile } = toRefs(props);

const price = computed(() =>
  GlobalUtils.Currency.formatCurrency(Number(props.inventory.price).toFixed(2), props.inventory.currency),
);
</script>

<style src="./SharedKitDropItemNew.style.scss" lang="scss" scoped></style>
