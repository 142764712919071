<template>
  <div class="save-link-mobile-component">
    <div class="save-link-mobile-component__container">
      <div class="save-link-mobile-component__header" @click="handleCancel">
        <SvgoArrowLeftIcon class="save-link-mobile-component__header-icon" />
        <div class="save-link-mobile-component__header-text">{{ $t('profile.saveLink.title') }}</div>
      </div>
      <div class="save-link-mobile-component__widget">
        <div class="save-link-mobile-component__item">
          <div class="save-link-mobile-component__item-head">
            <div>{{ $t('profile.saveLink.tradeLinkSteamTitle') }}</div>
            <SharedText
              align="center"
              class="save-link-mobile-component__item-head-text"
              :target="LinkTarget.LINK_BLANK"
              :to="ProfileSteamWidgetLinks.TRADE"
            >
              {{ $t('profile.saveLink.whereTakeLinkText') }}
            </SharedText>
          </div>
          <div class="save-link-mobile-component__item-description">
            {{ $t('profile.saveLink.tradeLinkSteamDescription') }}
          </div>
          <div class="save-link-mobile-component__item-input">
            <PaymentInputField
              v-model:value="localLink.trade"
              :error="errorText"
              :placeholder="$t('profile.saveLink.tradeInputPlaceholder')"
            />
            <SharedKitButton
              :is-disabled="!localLink.trade"
              :color-preset="BattleColorPresets.TRANSPARENT_WHITE"
              :size-preset="SizePresets.M"
              :type-preset="TypePresets.RECTANGLE"
              :text="$t('profile.steamTradeWidget.save')"
              @click="handleSave('trade', localLink.trade)"
            />
          </div>
        </div>
        <div class="save-link-mobile-component__item">
          <div class="save-link-mobile-component__item-head">
            <div>{{ $t('profile.saveLink.loginSteamTitle') }}</div>
            <SharedText
              align="center"
              class="save-link-mobile-component__item-head-text"
              :target="LinkTarget.LINK_BLANK"
              :to="ProfileSteamWidgetLinks.LOGIN"
            >
              {{ $t('profile.saveLink.whereTakeLinkText') }}
            </SharedText>
          </div>
          <div class="save-link-mobile-component__item-description">
            {{ $t('profile.saveLink.loginSteamDescription') }}
          </div>
          <div class="save-link-mobile-component__item-input">
            <PaymentInputField
              v-model:value="localLink.login"
              :placeholder="$t('profile.saveLink.loginInputPlaceholder')"
            />
            <SharedKitButton
              :is-disabled="!localLink.login"
              :color-preset="BattleColorPresets.TRANSPARENT_WHITE"
              :size-preset="SizePresets.M"
              :type-preset="TypePresets.RECTANGLE"
              :text="$t('profile.steamTradeWidget.save')"
              @click="handleSave('login', localLink.login)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ProfileSteamWidgetLinks } from '../SteamTradeWidget.data';
import type {
  IEmitSaveLinkMobileComponent,
  ISaveLinkMobileComponentProps,
  TSaveLinkWidgetMobileType,
} from './SaveLinkMobileComponent.types';
import { BattleColorPresets } from '@/features/battles/presets/SharedKitButton.presets';
import { SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { LinkTarget } from '~/constants/attributes';

const props = defineProps<ISaveLinkMobileComponentProps>();
const emit = defineEmits<IEmitSaveLinkMobileComponent>();

const localLink = ref({
  login: props.link.login,
  trade: props.link.trade,
});

watch(
  () => props.link,
  (newLink) => {
    localLink.value.login = newLink.login;
    localLink.value.trade = newLink.trade;
  },
  { deep: true },
);

const handleCancel = () => {
  emit('onCancel');
};

const handleSave = (type: TSaveLinkWidgetMobileType, value: string) => {
  emit('onLinkSave', {
    type,
    link: value,
  });
};
</script>

<style src="./SaveLinkMobileComponent.style.scss" scoped lang="scss" />
