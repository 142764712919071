import type {
  ISharedButtonNewColorPreset,
  ISharedButtonNewFormPreset,
  ISharedButtonTextPreset,
} from '~/types/SharedButtonNew.types';
import {
  defaultButtonColorPreset,
  defaultMediumButtonTextPreset,
  defaultMiddleButtonFormPreset,
} from '~/components/SharedButtonNew/SharedButtonNew.data';
import { Colors } from '~/constants/colors.constants';

const changeXL = GlobalUtils.Media.changeByMedia('xl');
const changeMD = GlobalUtils.Media.changeByMedia('md');

export const InventoryExchangePopupBottomsButtonFormPreset = computed<ISharedButtonNewFormPreset>(() => ({
  ...defaultMiddleButtonFormPreset,
  height: changeXL('50px', '40px'),
  minWidth: changeXL('132px', changeMD('90px', '100%')),
  radius: 'var(--border-radius-xs)',
  width: changeXL('132px', changeMD('90px', '100%')),
}));

export const InventoryExchangePopupBottomButtonColorPreset = computed<ISharedButtonNewColorPreset>(() => ({
  ...defaultButtonColorPreset,
  color: Colors.MAIN.PURPLE_200,
  colorHovered: Colors.MAIN.PURPLE_100,
}));

export const InventoryExchangePopupBottomButtonTextPreset = computed<ISharedButtonTextPreset>(() => ({
  ...defaultMediumButtonTextPreset,
  size: changeXL('var(--font-size-l)', 'var(--font-size-m)'),
}));
