export enum StatIcon {
  KNIVES = 'knives',
  MAX = 'max',
  OPEN_CASE = 'open_case',
  SCORE = 'score',
  STAR = 'star',
  STEAM = 'steam',
  UPGRADE = 'upgrade',
  WINRATE = 'winrate',
}

export interface IUserStatisticsBadgeProps {
  icon: StatIcon;
  title: string;
}
