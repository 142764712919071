/* eslint-disable @typescript-eslint/naming-convention */

export enum EProfileType {
  PERSONAL = 'Personal',
  FOREIGN = 'Foreign',
  BOT = 'Bot',
}

export interface IProfileTypeEvent {
  'Profile Type': EProfileType;
}
