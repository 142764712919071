<template>
  <ProfileStatBattles v-if="isLoaded" v-bind="props">
    <template v-if="!!$slots.footer" #footer>
      <slot name="footer" />
    </template>
  </ProfileStatBattles>
  <ProfileStatBaseSkeleton v-else :icons-count="5" />
</template>

<script setup lang="ts">
import type { IStatBattlesSkeletonProps } from './StatBattles.types';

const props = defineProps<IStatBattlesSkeletonProps>();
</script>
