import type { Component } from 'vue';
import type { EProfileTabName } from '~/repository/amplitude/types/profile/profile-tabs';

export enum ProfileBattlesFilter {
  ALL = 0,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
}

export interface IProfileBattlesFilter {
  id: ProfileBattlesFilter;
  localeKey: string;
  value: number;
}

export interface IProfileTab {
  component: Component;
  icon: string;
  id: EProfileTabName;
  localeKey: string;
  count?: string;
}
