import type { IProfileBattlesStore } from './ProfileBattles.types';
import { useProfilePresentationStore } from '~/features/profile/store/profilePresentation/ProfilePresentation.store';
import { useSingleBattleStore } from '~/features/battles/store/singleBattle.store';
import type {
  IProfileBattleCase,
  IProfileBattle,
  IGetProfileBattlesParams,
  IGetProfileBaseParams,
} from '~/repository/modules/profile/types/battles';
import { EProfileBattleStatus } from '~/repository/modules/profile/types/battles';

const PER_PAGE = 10;
const IS_BOT = 1;

/**
 * Сокращает количество одинаковых кейсов в баттле
 * @param cases
 * @return cases
 * @example reduceCases([{ caseId: 1 }, { caseId: 1 }, { caseId: 2 }]) // [{ caseId: 1, amount: 2 }, { caseId: 2 }]
 */
export function reduceCases(cases: IProfileBattleCase[]) {
  return cases.reduce((acc, currCase) => {
    const prevCase = acc[acc.length - 1];
    if (prevCase?.caseId === currCase.caseId) {
      prevCase.amount++;
      return acc;
    }
    return [...acc, currCase];
  }, [] as IProfileBattleCase[]);
}

export const useProfileBattlesStore = defineStore('profile/battles', (): IProfileBattlesStore => {
  const { $api } = useNuxtApp();
  const route = useRoute();
  const profilePresentationStore = useProfilePresentationStore();
  const singleBattleStore = useSingleBattleStore();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _data = ref([] as IProfileBattle[]);

  const data = computed<IProfileBattle[]>(() => {
    if (!isInitLoaded.value) return [];
    const activeBattle = _data.value.find(({ status }) =>
      [
        EProfileBattleStatus.CREATED,
        EProfileBattleStatus.ROLLED,
        EProfileBattleStatus.STARTED,
        EProfileBattleStatus.FINALIZED,
      ].includes(status),
    );
    const bestBattle = _data.value.find(({ isBest }) => isBest);

    return [
      ...(activeBattle ? [activeBattle] : []),
      ...(bestBattle ? [bestBattle] : []),
      ..._data.value
        // Удаляем старый бест батл
        .filter(({ isBest }) => !isBest)
        // Удаляем новый бест батл (добавляется выше)
        .filter(({ ulid }) => ulid !== (bestBattle?.ulid ?? ''))
        // Удаляем активный батл (добавляется выше)
        .filter(({ ulid }) => ulid !== (activeBattle?.ulid ?? '')),
    ];
  });

  const currentPage = ref(1);

  const isInitLoaded = ref<boolean>(false);
  // по дефолту ставим true, чтобы не блокировать кнопку "Загрузить еще" на начальной загрузке
  const isMoreLoaded = ref<boolean>(true);

  const isError = ref<boolean>(false);

  watch(
    () => profilePresentationStore.currentBattlesFilter,
    async (filter) => {
      try {
        isInitLoaded.value = false;
        await fetchAllBattles(filter, true);
        await fetchBestBattle(filter);
        isInitLoaded.value = true;
      } catch {
        isError.value = true;
      }
    },
  );

  function fetchAllBattles(memberCount: number, replace: boolean) {
    return GlobalUtils.Api.handleRequest(async () => {
      const params: IGetProfileBattlesParams = {
        page: currentPage.value,
        perPage: PER_PAGE,
        userId: Number(route.params.userId),
        isBot: IS_BOT,
        ...(memberCount ? { memberCount } : {}),
      };
      const resp = await $api.profile.getBattles(params);

      if (resp.status) {
        const battles = resp.data.items.map((battle) => ({ ...battle, casesCompact: reduceCases(battle.cases) }));
        if (replace) {
          _data.value = battles;
        } else {
          _data.value.push(...battles);
        }
      }
    });
  }

  function fetchBestBattle(memberCount: number) {
    return GlobalUtils.Api.handleRequest(async () => {
      const params: IGetProfileBaseParams = {
        userId: Number(route.params.userId),
        ...(memberCount ? { memberCount } : {}),
      };
      const resp = await $api.profile.getBestBattle(params);

      if (resp.status && resp.data.winnerId) {
        _data.value.push({ ...resp.data, casesCompact: reduceCases(resp.data.cases), isBest: true });
      }
    });
  }

  async function loadInit(membersCount = 0, replace = true) {
    isInitLoaded.value = false;
    try {
      await fetchAllBattles(membersCount, replace);
      await fetchBestBattle(membersCount);
      isInitLoaded.value = true;
    } catch {
      isError.value = true;
    }
  }

  async function loadMore(membersCount = 0, replace = false) {
    isMoreLoaded.value = false;
    currentPage.value += 1;
    try {
      await fetchAllBattles(membersCount, replace);
      isMoreLoaded.value = true;
    } catch {
      isError.value = true;
    }
  }

  async function cancelBattle(ulid: string, price: number) {
    await singleBattleStore.leaveBattle(ulid, price);
    _data.value = _data.value.filter((battle) => battle.ulid !== ulid);
  }

  return {
    data,
    isError,
    isInitLoaded,
    isMoreLoaded,
    loadInit,
    loadMore,
    cancelBattle,
  };
});
