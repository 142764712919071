import { useInventoryStore } from '~/store/inventory/inventory';
import DataMapper from '~/api/global/DataMapper';
import type { IWebsocketResponse } from '~/api/global/webosket/websocket.types';
import { WEBSOCKET_FIRST_CHANNEL } from '~/constants/app.constants';
import type { IResponseSendItem } from '~/features/bk/types/inventoryItems.types';
import { useUserStore } from '~/store/user/user.store';
import type { IInventory } from '~/repository/modules/inventory/inventory.types';
import { EInventoryItemStatus } from '~/repository/modules/inventory/inventory.types';
import { errorKeyCodes } from '~/features/bk/constants/codes.error';
import { useAlertStore } from '~/store/alert/alert.store';
import { inventoryWsTransformTo } from '~/store/inventory/helper';
import type { IWSStateChangeEvent } from '~/repository/modules/bk/bk.types';

import type { TUserIdentifier } from '~/store/inventory/types';
import type { IAlert } from '~/store/alert/alert.types';
import { AlertCodes } from '~/store/alert/alert.messages';

export const useInventoryWsStore = defineStore('inventory-ws', () => {
  const {
    $api,
    $i18n: { t },
  } = useNuxtApp();
  const inventoryStore = useInventoryStore();
  const { userInventory, total } = storeToRefs(inventoryStore);

  const { user } = storeToRefs(useUserStore());

  const alertStore = useAlertStore();

  const unixTimeCap = 300;

  const mapper = new DataMapper();
  const itemIdsToReplaceInQueue = ref<Record<number, number>>({});
  const tradeOfferMap = ref<Map<number, number>>(new Map([]));

  function getAlertStatus(status: EInventoryItemStatus): IAlert | undefined {
    switch (status) {
      case EInventoryItemStatus.ACCEPTED:
        return {
          title: AlertCodes.ITEM_RECEIVED,
          type: 'success',
        };
      case EInventoryItemStatus.CANCELED:
        return {
          title: AlertCodes.ITEM_TRANSFER_CANCELED,
          type: 'success',
        };
      case EInventoryItemStatus.SEND:
        return {
          title: AlertCodes.ITEM_IN_WITHDRAWAL_QUEUE,
          type: 'success',
        };
      case EInventoryItemStatus.ERROR:
        return {
          title: AlertCodes.ERROR_WITHDRAWAL,
          type: 'error',
        };
    }
  }

  function changeStatusItem(inventoryId: number, status: EInventoryItemStatus) {
    if (!userInventory.value?.length) return;
    userInventory.value = userInventory.value?.map((item: IInventory) => {
      if (+item.id === +inventoryId) {
        const alert = getAlertStatus(status);
        if (alert) {
          alertStore.show(alert);
        }

        return {
          ...item,
          status,
        };
      }
      return item;
    }) as IInventory[];
  }

  function getStatusErrorCode(msg: string): number {
    const str = msg.split(':');
    if (str[1]) return parseFloat(str[1]);
    return 5005;
  }

  function getErrorMultiLanguageKey(key: keyof typeof errorKeyCodes): string {
    return errorKeyCodes[key];
  }

  function parseAndMapData<T>(data: IWebsocketResponse) {
    const parsed = JSON.parse(data.msg);
    return mapper.mapDataKeys(parsed) as T;
  }

  function isEventForCurrentUser(mapData: { user: TUserIdentifier }, userId: TUserIdentifier): boolean {
    return +mapData.user === +userId;
  }

  function handleItemStatusChange(itemId: number, status: EInventoryItemStatus) {
    changeStatusItem(itemId, status);
  }

  function removeItemFromReplacementQueue(inventoryId: number) {
    delete itemIdsToReplaceInQueue.value[inventoryId];
  }

  const connectWsItemResponse = () => {
    return $api.websocket.subscribe(WEBSOCKET_FIRST_CHANNEL, {
      cb: (data: IWebsocketResponse) => {
        const mapData = parseAndMapData<IResponseSendItem>(data);

        if (!user.value || !isEventForCurrentUser(mapData, user.value.userId)) return;

        if (
          (!mapData.response.msg && !mapData.response.replacements?.items?.length) ||
          Object.hasOwn(mapData.response, '')
        ) {
          handleItemStatusChange(+mapData.inventoryId, EInventoryItemStatus.VENDOR_WAITING);
          return;
        }

        if (!mapData.response.status) {
          if (mapData.response.type === EInventoryItemStatus.SOLD) {
            if (!mapData.inventoryId) return;
            handleItemStatusChange(+mapData.inventoryId, EInventoryItemStatus.CANCELED);
            removeItemFromReplacementQueue(+mapData.inventoryId);
            alertStore.showError({ title: t('checkInventoryOpenForTake') });
          }
          const msg = mapData.response.msg;
          const errorCode = getStatusErrorCode(msg);
          if (!errorCode) return;
          const key = getErrorMultiLanguageKey(errorCode);
          if (!key) throw new Error(`Ключ не найден: ${key}, ${msg}`);
          alertStore.showError({ title: t(key) });

          if (mapData.inventoryId) {
            removeItemFromReplacementQueue(+mapData.inventoryId);
          }
        }

        if (mapData.response?.replacements?.items?.length) {
          const transformArr = inventoryWsTransformTo(mapData.response.replacements.items, user.value);
          const itemTo = userInventory.value?.find((item) => +item.id === +mapData.response.inventoryId);
          if (!itemTo) return;
          itemTo.status = EInventoryItemStatus.EXCHANGING;
          itemTo.timerData = mapData.response.replacements.offerEndTime;
          itemTo.replacementItems = [...transformArr];

          removeItemFromReplacementQueue(+itemTo.id);
        }
      },
      event: 'senditem_response',
      uniqueKey: 'profile-inventory/items-to-replacement',
    }) as () => boolean;
  };

  const connectWsStateChange = () => {
    return $api.websocket.subscribe(WEBSOCKET_FIRST_CHANNEL, {
      cb: (data: IWebsocketResponse) => {
        const mapData = parseAndMapData<IWSStateChangeEvent>(data);

        if (!user.value || !isEventForCurrentUser(mapData, user.value.userId)) return;

        if (mapData.offer) tradeOfferMap.value.set(+mapData.item, +mapData.offer);
        const statusKey = Object.keys(EInventoryItemStatus).find(
          (key) => EInventoryItemStatus[key as keyof typeof EInventoryItemStatus] === mapData.type,
        );

        if (statusKey) {
          handleItemStatusChange(+mapData.item, EInventoryItemStatus[statusKey as keyof typeof EInventoryItemStatus]);
        }

        if (mapData.type === 'canceled') {
          const canceledItem = userInventory.value?.find((item) => +item.id === +mapData.item);
          removeItemFromReplacementQueue(+mapData.item);
          alertStore.showError({ title: t('alerts.itemSendCancelled') });
          if (canceledItem) {
            canceledItem.status = EInventoryItemStatus.PROGRESS;
          }
        }

        if (mapData.type === 'send') {
          const sendItem = userInventory.value?.find((item) => +item.id === +mapData.item);
          if (sendItem) {
            sendItem.status = EInventoryItemStatus.COMPLETED;
            sendItem.timerData = unixTimeCap;
          }
        }

        if (mapData.type === 'accept') {
          const item = userInventory.value?.find((item) => +item.id === +mapData.item);
          if (!item) return;
          if (userInventory.value) {
            userInventory.value = userInventory.value?.filter((item) => +item.id !== +mapData.item) ?? [];
            total.value--;
          }
          // Под вопросом
          userInventory.value && total.value++;
        }
      },
      event: 'statechange',
      uniqueKey: 'profile-inventory/state-change',
    }) as () => boolean;
  };

  const getItem = (id: number) => {
    if (!tradeOfferMap.value.has(id)) return;
    window.open(`https://steamcommunity.com/tradeoffer/${tradeOfferMap.value.get(id)}/}`, '_blank');
  };

  return {
    connectWsItemResponse,
    connectWsStateChange,
    getItem,
    unixTimeCap,
  };
});
