<script setup lang="ts">
import type { IBaseForegroundProps } from '~/features/skeleton/components/BaseForeground/BaseForeground.types';

const changeXL = GlobalUtils.Media.changeByMedia('xl');
const changeMD = GlobalUtils.Media.changeByMedia('md');

const isMobile = computed(() => {
  return changeMD(false, true);
});

const itemsQuantity = computed(() => {
  return changeMD(5, 1);
});

const containerProps = computed<IBaseForegroundProps>(() => ({
  borderRadius: changeXL(8, 6),
  width: 50,
  height: 50,
}));
</script>

<template>
  <SkeletonComponentLoader>
    <section>
      <SkeletonBaseBackground class="upgrades-item-skeleton" v-bind="containerProps">
        <div v-if="isMobile" class="upgrades-item-skeleton__result">
          <SkeletonBaseForeground class="upgrades-item-skeleton__result-item" v-bind="containerProps" />
          <SkeletonBaseForeground class="upgrades-item-skeleton__result-item" v-bind="containerProps" />
        </div>
        <div class="upgrades-item-skeleton__left">
          <SkeletonBaseForeground class="upgrades-item-skeleton__prize" v-bind="containerProps" />
          <div v-if="isMobile" class="upgrades-item-skeleton__info">
            <SkeletonBaseForeground class="upgrades-item-skeleton__info-price" v-bind="containerProps" />
            <SkeletonBaseForeground class="upgrades-item-skeleton__info-name" v-bind="containerProps" />
            <SkeletonBaseForeground
              class="upgrades-item-skeleton__info-name upgrades-item-skeleton__info-name--last"
              v-bind="containerProps"
            />
          </div>
        </div>
        <div class="upgrades-item-skeleton__content">
          <div v-if="!isMobile" class="upgrades-item-skeleton__result">
            <SkeletonBaseForeground class="upgrades-item-skeleton__result-item" v-bind="containerProps" />
            <SkeletonBaseForeground class="upgrades-item-skeleton__result-item" v-bind="containerProps" />
          </div>
          <div class="upgrades-item-skeleton__list">
            <SkeletonBaseForeground
              v-for="i in itemsQuantity"
              :key="i"
              class="upgrades-item-skeleton__list-item"
              v-bind="containerProps"
            />
          </div>
        </div>
      </SkeletonBaseBackground>
    </section>
  </SkeletonComponentLoader>
</template>

<style scoped lang="scss" src="./UpgradesItem.skeleton.scss" />
