export enum EProfileAdditionalEvents {
  TRADE_LINK_CLICKED = 'Profile - Trade Link Clicked',
  STEAM_LOGIN_CLICKED = 'Profile - Steam Login Clicked',
  TRADE_LINK_INSTRUCTION_CLICKED = 'Profile - Trade Link Instruction Clicked',
  STEAM_LOGIN_INSTRUCTION_CLICKED = 'Profile - Steam Login Instruction Clicked',
  TRADE_LINK_RECEIVED = 'Profile - Trade Link Received',
  STEAM_LOGIN_RECEIVED = 'Profile - Steam Login Received',
  TRADE_LINK_SAVED = 'Profile - Trade Link Saved',
  STEAM_LOGIN_SAVED = 'Profile - Steam Login Saved',
}
