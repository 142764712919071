<template>
  <div class="upgrades-wrapper">
    <ProfileStatUpgradesSkeleton v-bind="statUpgradesStore.state" :is-loaded="statUpgradesStore.isLoaded" />
    <ProfileUpgradesList
      :items="upgrades"
      :is-last-page="upgradesStore.pagination.isLastPage"
      :is-pending="profileUpgradesPending"
      @load="loadMoreHandler"
    />
  </div>
</template>

<script setup lang="ts">
import { useProfilePresentationStore } from '../../store/profilePresentation/ProfilePresentation.store';
import { useStatUpgradesStore } from '~/features/profile/store/statUpgrades/StatUpgrades.store';
import { useProfileUpgradeStore } from '~/features/profile/store/profileUpgrades/profileUpgrades.store';
import { useUserStore } from '~/store/user/user.store';
import { ProfileTabsEvents } from '~/repository/amplitude/events/profile/profile-tabs';

const { isUserLoaded } = storeToRefs(useUserStore());

const statUpgradesStore = useStatUpgradesStore();
const upgradesStore = useProfileUpgradeStore();
const profilePresentationStore = useProfilePresentationStore();

const { upgrades, profileUpgradesPending } = storeToRefs(upgradesStore);

const loadMoreHandler = () => {
  ProfileTabsEvents.loadMoreClicked({
    'Tab Name': profilePresentationStore.currentTab,
  });

  upgradesStore.pagination.loadMore();
};

onMounted(async () => {
  if (isUserLoaded.value) {
    await upgradesStore.refreshProfileUpgrades();
  }

  if (statUpgradesStore.isLoaded) return;
  await statUpgradesStore.fetch();
});
</script>

<style src="./ProfileUpgradesTabController.style.scss" scoped lang="scss"></style>
