<template>
  <div class="battles-wrapper" :class="{ 'battles-wrapper--bot': currentUser?.isBot }">
    <ProfileStatBattlesSkeleton v-bind="statBattlesStore.data" :is-loaded="statBattlesStore.isLoaded">
      <template v-if="isStatFooterVisible" #footer>
        <ProfileBattlesFiltersBar
          :filters="battlesFilters"
          :active-filter="battlesFiltersMap[profilePresentationStore.currentBattlesFilter]"
          @activate-filter="profilePresentationStore.activateBattlesFilter"
        />
        <SharedKitButton class="battles-footer_button" v-bind="makeBattleButtonProps">
          <template #prepend>
            <SvgoProfileStatisticsPlus />
          </template>
          <template #default>
            {{ $t('profile.statWidget.makeBattleButton') }}
          </template>
        </SharedKitButton>
      </template>
    </ProfileStatBattlesSkeleton>
    <ProfileBattlesListSkeleton
      :is-init-loaded="profileBattlesStore.isInitLoaded"
      :is-more-loaded="profileBattlesStore.isMoreLoaded"
      :battles="profileBattlesStore.data"
      @load-more="loadMoreHandler"
    />
  </div>
</template>

<script setup lang="ts">
import { useStatBattlesStore } from '~/features/profile/store/statBattles/StatBattles.store';
import type { ISharedKitButtonProps } from '~/components/SharedKitButton/SharedKitButton.types';
import { Colors } from '~/constants/colors.constants';
import { useProfileBattlesStore } from '~/features/profile/store/profileBattles/ProfileBattles.store';
import {
  battlesFilters,
  battlesFiltersMap,
} from '~/features/profile/store/profilePresentation/ProfilePresentation.data';
import { useProfilePresentationStore } from '~/features/profile/store/profilePresentation/ProfilePresentation.store';
import { ProfileBattlesFilter } from '~/features/profile/store/profilePresentation/ProfilePresentation.types';
import { ProfileTabsEvents } from '~/repository/amplitude/events/profile/profile-tabs';
import { useUserStore } from '~/store/user/user.store';

const { currentUser } = storeToRefs(useUserStore());

const profilePresentationStore = useProfilePresentationStore();
const isStatFooterVisible = computed(() => {
  return (
    profileBattlesStore.data.length > 0 || profilePresentationStore.currentBattlesFilter !== ProfileBattlesFilter.ALL
  );
});

const statBattlesStore = useStatBattlesStore();
onMounted(async () => {
  if (statBattlesStore.isLoaded) return;
  await statBattlesStore.fetch();
});

const profileBattlesStore = useProfileBattlesStore();
onMounted(async () => {
  if (profileBattlesStore.isInitLoaded) return;
  await profileBattlesStore.loadInit();
});

const makeBattleButtonProps: ISharedKitButtonProps = {
  colorPreset: {
    defaultBackground: Colors.MAIN.PURPLE_200,
    hoverBackground: Colors.MAIN.PURPLE_300,
    loadingColor: Colors.MAIN.PURPLE_400,
    textColor: Colors.NEUTRAL.WHITE,
  },
  linkProps: { to: '/battles/create' },
  sizePreset: { fontSize: 10, height: 32, type: 'default' },
  typePreset: { default: 80, large: 80, medium: 80 },
};

const loadMoreHandler = () => {
  ProfileTabsEvents.loadMoreClicked({
    'Tab Name': profilePresentationStore.currentTab,
  });

  profileBattlesStore.loadMore();
};
</script>

<style src="./ProfileBattlesTabController.style.scss" scoped lang="scss"></style>
