<template>
  <section class="withdrawal-history-table_container">
    <div class="withdrawal-history-table_head">
      <span v-for="{ textAlign, ...col } in cols" :key="col.name" :style="{ textAlign }">{{ col.displayName }}</span>
    </div>
    <div class="withdrawal-history-table_body">
      <div v-for="(row, i) in rows" :key="i" class="withdrawal-history-table_row">
        <div
          v-for="({ textAlign, color, ...col }, j) in orderedCols"
          :key="j"
          :style="{ textAlign }"
          class="withdrawal-history-table_cell"
        >
          <span class="withdrawal-history-table_cell_label">{{ row[col.name].label }}</span>
          <span class="withdrawal-history-table_cell_value" :style="{ color }">
            {{ formatCellValue(row[col.name].value) }}
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { IWithdrawalHistoryTableProps } from './WithdrawalHistoryTable.types';

const props = defineProps<IWithdrawalHistoryTableProps>();

const orderedCols = [...props.cols].sort((a, b) => a.ordering - b.ordering);

/**
 * Форматирование значения ячейки
 * @param value - значение ячейки
 * @returns отформатированное значение
 */
function formatCellValue(value: string | number | Date): string {
  if (value instanceof Date)
    return value
      .toISOString() // '2024-07-15T09:04:46.387Z'
      .replace('T', ' ') // '2024-07-15 09:04:46.387Z'
      .replace(/\.\d*\w/, ''); // '2024-07-15 09:04:46'
  return value.toString();
}
</script>

<style scoped src="./WithdrawalHistoryTable.scss" lang="scss"></style>
