<script setup lang="ts">
import SharedSwitcher from '~/components/SharedSwitcher/SharedSwitcher.vue';
import { useInventoryStore } from '~/store/inventory/inventory';
import {
  InventoryActionsButtonColorPreset,
  InventoryActionsButtonFormPreset,
  InventoryActionsButtonSmall,
  InventoryActionsButtonTextPreset,
} from '~/features/profile/widgets/InventoryActions/InventoryActions.data';
import SaleSummary from '~/features/quickSale/components/SaleSummary/SaleSummary.vue';
import { useUserStore } from '~/store/user/user.store';

const { t } = useI18n();

const changeMD = GlobalUtils.Media.changeByMedia('md');

const userStore = useUserStore();
const inventoryStore = useInventoryStore();
const { totalPrice, total, isAllInventory, selectedInventoryIds, totalSumSelectedItems, quantitySelectedItems } =
  storeToRefs(inventoryStore);

const isDisabled = computed(() => {
  return total.value <= 0;
});

const showNotification = computed(() => {
  return Number(totalSumSelectedItems.value) < 100 && selectedInventoryIds.value.length;
});

const sellText = computed(() => {
  const textKey = isDisabled.value ? 'inventorySellAll' : 'inventorySellAllFor';
  return changeMD(t(textKey), t('sell'));
});
</script>

<template>
  <section class="inventory-actions">
    <div v-if="!quantitySelectedItems" class="inventory-actions__buttons">
      <SharedButtonNew
        class="inventory-actions__buttons-button inventory-actions__buttons-button--sell"
        :class="{ 'inventory-actions__buttons-button--disabled': isDisabled }"
        :form="InventoryActionsButtonFormPreset"
        :color="InventoryActionsButtonColorPreset"
        :state="{ disabled: isDisabled }"
        @click="inventoryStore.sellAllItems()"
      >
        {{ sellText }}
        <template #append>
          <span v-if="Number(totalPrice) > 0" class="inventory-actions__buttons-button-price">
            {{ parseFloat(totalPrice).toFixed(2) }}
          </span>
          <UserController v-if="Number(totalPrice) > 0">
            <template #default="{ isFake }">
              <SharedPrettyCurrency
                class="inventory-actions__buttons-button-price"
                :currency="userStore.user?.finance.currency"
                :is-fake="isFake"
              />
            </template>
          </UserController>
        </template>
      </SharedButtonNew>
      <SharedButtonNew
        class="inventory-actions__buttons-button"
        :class="{ 'inventory-actions__buttons-button--disabled': isDisabled }"
        :form="InventoryActionsButtonFormPreset"
        :color="InventoryActionsButtonColorPreset"
        :state="{ disabled: isDisabled }"
        :text-props="InventoryActionsButtonTextPreset"
        :link-props="{
          to: useLinks().UPGRADES,
          target: '_blank',
        }"
      >
        <template #prepend>
          <SvgoUpgradeIconNoBg class="inventory-actions__buttons-button-icon" />
        </template>
        {{ $t('inventoryUpgradeAll') }}
      </SharedButtonNew>
    </div>

    <div v-else class="inventory-actions__select-state">
      <SharedButtonNew
        class="inventory-actions__select-state-btn"
        :form="InventoryActionsButtonSmall"
        :color="InventoryActionsButtonColorPreset"
        @click="inventoryStore.removeAllFromSelected()"
      >
        {{ $t('profile.inventoryActions.cancel') }}
      </SharedButtonNew>
      <div v-if="showNotification" class="inventory-actions__notification">
        <SvgoInfoAgreementIcon />
        <span>{{ $t('profile.inventoryActions.minimalSum') }} 100 ₽ </span>
      </div>
    </div>

    <SharedSwitcher v-if="!quantitySelectedItems" v-model="isAllInventory">
      {{ $t('inventoryProfile.allItems') }}
    </SharedSwitcher>
    <SaleSummary
      v-else
      :sale-sum="totalSumSelectedItems"
      :selected-count="quantitySelectedItems"
      :all-count="total"
      item-type
      @sell="inventoryStore.sendItem(selectedInventoryIds)"
    />
  </section>
</template>

<style scoped lang="scss" src="./InventoryActions.scss" />
