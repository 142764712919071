import { EProfileUpgradesEvents } from '~/repository/amplitude/types/profile/profile-upgrades';
import type { IProfileTypeEvent } from '~/repository/amplitude/types/profile/profile-common';

export class ProfileUpgradesEvents {
  static fullUpgradeClicked(event: IProfileTypeEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileUpgradesEvents.FULL_UPGRADE_CLICKED,
      eventProperties: event,
    });
  }

  static createUpgradeClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileUpgradesEvents.CREATE_UPGRADE_CLICKED,
    });
  }
}
