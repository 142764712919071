import type { ISharedIconTypePreset } from './SharedIcon.types';

type TTypePresetsKeys = 'ROUND' | 'NONE';

export const TypePresets: Record<TTypePresetsKeys, ISharedIconTypePreset> = {
  NONE: {
    large: 0,
    medium: 0,
  },
  ROUND: {
    large: 100,
    medium: 100,
  },
};
