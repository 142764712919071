<script setup lang="ts">
import type { InventoryItemInfoProps } from './InventoryItemInfo.types';
import type {
  IInventoryItemCameFrom,
  IInventoryItemStatus,
} from '~/features/profile/components/InventoryItem/InventoryItem.types';
import { EInventoryItemSource } from '~/repository/modules/inventory/inventory.types';

const props = withDefaults(defineProps<InventoryItemInfoProps>(), {
  cameFrom: EInventoryItemSource.CASES,
  cameFromInfo: () => ({}) as IInventoryItemCameFrom,
  currency: 'RUB',
  isItemOld: false,
  isParentHovered: false,
  name: '',
  price: '',
  status: () => ({}) as IInventoryItemStatus,
});

const isNeedLineClampLabel = computed<boolean>(() => {
  return props.isItemOld;
});
</script>

<template>
  <div
    class="inventory-item-info"
    :class="{ 'inventory-item-info--from-case': cameFrom === EInventoryItemSource.CASES }"
  >
    <div class="inventory-item-info__top">
      <div class="inventory-item-info__top-price">
        <span class="inventory-item-info__top-price-amount">{{ price }}</span>
        <UserController>
          <template #default="{ isFake }">
            <SharedPrettyCurrency
              class="inventory-item-info__top-price-currency"
              :currency="currency"
              :is-fake="isFake"
            />
          </template>
        </UserController>
      </div>
      <div v-if="isItemOld" class="inventory-item-info__top-icon">
        <component :is="status.component" :style="{ color: status.color }" :width="16" :height="16" />
      </div>
    </div>
    <div class="inventory-item-info__name" :class="{ 'inventory-item-info__name_line-clamp_1': isNeedLineClampLabel }">
      {{ name }}
    </div>
    <div class="inventory-item-info__status" :style="!isParentHovered ? { color: status.color } : {}">
      {{ isParentHovered ? cameFromInfo.text : status.text }}
    </div>
  </div>
</template>

<style scoped lang="scss" src="./InventoryItemInfo.scss" />
