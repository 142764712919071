import type { IWithdrawalHistoryTableCol, IWithdrawalHistoryTableRow } from './WithdrawalHistory.types';
import { CurrencyName } from '~/constants/currency.constants';
import { EGroupSortParams, type IInventory } from '~/repository/modules/inventory/inventory.types';

export const useWithdrawalHistory = defineStore('profile/withdrawalHistory', () => {
  const { $api } = useNuxtApp();
  const { tm, rt } = useI18n();

  const isLoaded = ref(false);
  const isEmpty = computed(() => isLoaded.value && !rows.value.length);

  const rows = ref<IWithdrawalHistoryTableRow[]>([]);

  // `tm` возвращает итерируемый объект, но TS не понимает, что он итерируемый, по этому кастуем его к массиву колонок
  const cols = Array.from(tm('profile.withdrawalHistory.cols') as IWithdrawalHistoryTableCol[]).map(
    ({ name, displayName, ordering, textAlign = 'left', color = 'inherit' }) => ({
      // строки внутри объекта хранятся в своём формате, по этому вызываем `rt` на них
      color: rt(color),
      displayName: rt(displayName),
      name: rt(name),
      ordering, // а числа и тут числа
      textAlign: rt(textAlign),
    }),
    // и ещё раз кастуем к массиву колонок получившийся объект
  ) as IWithdrawalHistoryTableCol[];

  const colLabelsMap: Record<string, string> = cols.reduce(
    (acc, { name, displayName }) => ({ ...acc, [name]: displayName }),
    {},
  );

  function convertDtoToModel({ id, updateAt, name, price }: IInventory): IWithdrawalHistoryTableRow {
    return {
      date: {
        label: colLabelsMap.updatedAt,
        value: new Date(updateAt),
      },
      id: {
        label: colLabelsMap.id,
        value: id.toString(),
      },
      name: {
        label: colLabelsMap.name,
        value: name,
      },
      price: {
        label: colLabelsMap.price,
        // TODO: брать валюту из настроек профиля
        value: `${price} ${CurrencyName.RUB}`,
      },
    };
  }

  function fetchTable(page = 1, perPage = 20) {
    return GlobalUtils.Api.handleRequest(async () => {
      isLoaded.value = false;
      const { data } = await $api.inventory.getInventory({
        page,
        perPage,
        group: EGroupSortParams.WITHDRAWAL,
      });
      rows.value.push(...data.items.map(convertDtoToModel));
      isLoaded.value = true;
    });
  }

  return {
    cols,
    fetchTable,
    isEmpty,
    isLoaded,
    rows,
  };
});
