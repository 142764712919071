<template>
  <ProfileStatBase :header="header">
    <template #badges>
      <ProfileStatIconBadge v-for="badge in badges" :key="badge.id" :title="badge.title" :icon="badge.icon">
        {{ badge.value }}
      </ProfileStatIconBadge>
      <ProfileStatIconBadge :icon="StatIcon.SCORE" :title="$t('profile.statWidget.badges.battles.lastFiveWinner')">
        <template #no-gradient>
          <div class="user-statistics-winLooseStrike">
            <span v-for="(result, i) in lastFiveWinner" :key="i" :class="winLooseClassesMap[result]">{{ result }}</span>
          </div>
        </template>
      </ProfileStatIconBadge>
    </template>
    <template v-if="!!$slots.footer" #footer>
      <slot name="footer" />
    </template>
  </ProfileStatBase>
</template>

<script setup lang="ts">
import type { IStatBattlesProps } from './StatBattles.types';
import { StatIcon } from '~/features/profile/components/StatIconBadge/StatIconBadge.types';
import { PaymentColors } from '~/features/payment/constants/colors.constants';

const props = defineProps<IStatBattlesProps>();

const { t } = useI18n();

const header = t('profile.statWidget.headers.battles');

const badges = [
  {
    icon: StatIcon.KNIVES,
    id: 0,
    title: t('profile.statWidget.badges.battles.allCount'),
    value: props.allCount,
  },
  {
    icon: StatIcon.STAR,
    id: 1,
    title: t('profile.statWidget.badges.battles.winCount'),
    value: props.winCount,
  },
  {
    icon: StatIcon.WINRATE,
    id: 2,
    title: t('profile.statWidget.badges.battles.winRate'),
    value: props.winRate,
  },
  {
    icon: StatIcon.MAX,
    id: 3,
    title: t('profile.statWidget.badges.battles.maxDrop'),
    value: props.maxDrop,
  },
];

const winLooseClassesMap: Record<string, string> = {
  L: 'user-statistics-winLooseStrike_loose',
  W: 'user-statistics-winLooseStrike_win',
};

const winColor = PaymentColors.SUCCESS;
const looseColor = PaymentColors.FAIL;
</script>

<style scoped>
.user-statistics-winLooseStrike {
  --win-color: v-bind(winColor);
  --loose-color: v-bind(looseColor);
}
</style>

<style scoped src="./StatBattles.scss" lang="scss"></style>
