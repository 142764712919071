<template>
  <div class="steam-trade-widget">
    <SharedButtonsGroup
      v-if="!isShowWidget && !mobileWidgetVersion"
      :border-radius="8"
      :direction="directionButton"
      :gap="2"
      class="steam-trade-widget__buttons"
    >
      <SharedKitButton
        :color-preset="BattleColorPresets.TRANSPARENT_WHITE"
        :size-preset="actionButtonsSize"
        :type-preset="TypePresets.NONE"
        :text="$t('profile.steamTradeWidget.tradeLink')"
        @click="handleOpenWidget('trade')"
      >
        <template v-if="userStore.user?.steam.link" #append> <SvgoCheckWhBg /> </template>
      </SharedKitButton>

      <SharedKitButton
        :color-preset="BattleColorPresets.TRANSPARENT_WHITE"
        :size-preset="actionButtonsSize"
        :type-preset="TypePresets.NONE"
        :text="$t('profile.steamTradeWidget.steamLogin')"
        @click="handleOpenWidget('login')"
      >
        <template v-if="userStore.user?.steam.login" #append> <SvgoCheckWhBg /> </template>
      </SharedKitButton>
    </SharedButtonsGroup>
    <div v-else-if="!isShowWidget && mobileWidgetVersion" class="steam-trade-widget__mobile">
      <div class="steam-trade-widget__button" @click="handleOpenMobileWidget">
        {{ $t('profile.saveLink.title') }}
        <SvgoArrowRightIcon class="steam-trade-widget__button-icon" />
      </div>
      <div class="steam-trade-widget__button" @click="handleOpenWithdrawalTab">
        {{ $t('profile.tabs.withdrawals') }}
        <SvgoArrowRightIcon class="steam-trade-widget__button-icon" />
      </div>
    </div>
    <component
      :is="mobileWidgetVersion ? SaveLinkMobileComponent : SaveLinkComponent"
      v-if="isShowWidget"
      :type="pathForWidget"
      :link="{
        trade: userStore.user?.steam.link ?? '',
        login: userStore.user?.steam.login ?? '',
      }"
      :error-text="errorText"
      @on-cancel="handleWidgetShow"
      @on-link-save="handleSaveLink"
    />
  </div>
</template>

<script setup lang="ts">
import SaveLinkComponent from './SaveLinkComponent/SaveLinkComponent.vue';
import SaveLinkMobileComponent from './SaveLinkMobileComponent/SaveLinkMobileComponent.vue';
import type {
  ISaveLinkEmit,
  TSaveLinkWidgetType,
} from '~/features/profile/widgets/SteamTradeWidget/SaveLinkComponent/SaveLinkComponent.types';
import { BattleColorPresets } from '~/features/battles/presets/SharedKitButton.presets';
import { SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { useProfilePresentationStore } from '~/features/profile/store/profilePresentation/ProfilePresentation.store';
import { useUserStore } from '~/store/user/user.store';
import { EButtonsGroupDirection } from '~/components/SharedButtonsGroup/SharedButtonsGroup.types';
import { ProfileAdditionalEvents } from '~/repository/amplitude/events/profile/profile-additional';
import { EProfileTabName } from '~/repository/amplitude/types/profile/profile-tabs';

const profilePresentationStore = useProfilePresentationStore();
const userStore = useUserStore();

const changeXL = GlobalUtils.Media.changeByMedia('xl');
const changeMD = GlobalUtils.Media.changeByMedia('md');

const actionButtonsSize = computed(() => changeXL(SizePresets.M, SizePresets.S));
const directionButton = computed(() => changeXL(EButtonsGroupDirection.COLUMN, EButtonsGroupDirection.ROW));

const mobileWidgetVersion = computed(() => changeMD(false, true));

const isShowWidget = ref<boolean>(false);
const pathForWidget = ref<TSaveLinkWidgetType>('');

const errorText = ref('');
const handleWidgetShow = () => {
  errorText.value = '';
  isShowWidget.value = !isShowWidget.value;
  document.body.classList.remove('shared-modal-opened');
  document.body.style.overflow = 'visible';
};

const validateSteamLink = (link: string): boolean => {
  const steamTradeLinkPattern = /^https:\/\/steamcommunity\.com\/tradeoffer\/new\/\?partner=\d+&token=[a-zA-Z0-9-_]+$/;
  return steamTradeLinkPattern.test(link);
};

const { t } = useI18n();

const saveSteamLink = (link: string, type: string): void => {
  if (type === 'login') {
    userStore.setSteamData({ login: link });
    handleWidgetShow();
  } else if (type === 'trade') {
    if (validateSteamLink(link)) {
      userStore.setSteamData({ link });
      handleWidgetShow();
    } else {
      errorText.value = t('profile.steamTradeWidget.errors.wrongLink');
    }
  }
};

const handleSaveLink = (linkObj: ISaveLinkEmit): void => {
  const { link, type } = linkObj;

  if (!link) {
    type === 'trade'
      ? (errorText.value = t('profile.steamTradeWidget.errors.noLink'))
      : (errorText.value = t('profile.steamTradeWidget.errors.noLogin'));
    return;
  }
  saveSteamLink(link, type);
};

const handleOpenWidget = (path: TSaveLinkWidgetType) => {
  pathForWidget.value = path;

  pathForWidget.value === 'trade'
    ? ProfileAdditionalEvents.tradeLinkClicked()
    : ProfileAdditionalEvents.steamLoginClicked();

  handleWidgetShow();
};

const handleOpenMobileWidget = () => {
  handleWidgetShow();
  if (!document) return;

  document.body.classList.add('shared-modal-opened');
  document.body.style.overflow = 'hidden';
};

const handleOpenWithdrawalTab = () => {
  profilePresentationStore.activateTab(EProfileTabName.WITHDRAWAL);
};
</script>

<style src="./SteamTradeWidget.style.scss" scoped lang="scss"></style>
