import type { IProfileBattlesFilter, IProfileTab } from './ProfilePresentation.types';
import { ProfileBattlesFilter } from './ProfilePresentation.types';
import ProfileBattlesTabController from '~/features/profile/controllers/ProfileBattlesTabController/ProfileBattlesTabController.vue';
import ProfileInventoryTabController from '~/features/profile/controllers/ProfileInventoryTabController/ProfileInventoryTabController.vue';
import ProfileUpgradesTabController from '~/features/profile/controllers/ProfileUpgradesTabController/ProfileUpgradesTabController.vue';
import ProfileWithdrawalsTabController from '~/features/profile/controllers/ProfileWithdrawalsTabController/ProfileWithdrawalsTabController.vue';
import { EProfileTabName } from '~/repository/amplitude/types/profile/profile-tabs';
import { useUserStore } from '~/store/user/user.store';

export const tabs: IProfileTab[] = [
  {
    component: ProfileInventoryTabController,
    icon: 'SvgoProfileInventory',
    id: EProfileTabName.INVENTORY,
    localeKey: 'profile.tabs.inventory',
  },
  {
    component: ProfileBattlesTabController,
    icon: 'SvgoProfileBattles',
    id: EProfileTabName.BATTLES,
    localeKey: 'profile.tabs.battles',
  },
  {
    component: ProfileUpgradesTabController,
    icon: 'SvgoProfileUpgrades',
    id: EProfileTabName.UPGRADES,
    localeKey: 'profile.tabs.upgrades',
  },
  {
    component: ProfileWithdrawalsTabController,
    icon: 'SvgoProfileWithdrawals',
    id: EProfileTabName.WITHDRAWAL,
    localeKey: 'profile.tabs.withdrawals',
  },
];

export const tabsMap = computed(() => {
  const userStore = useUserStore();
  const { isCurrentUser } = storeToRefs(userStore);

  const filteredTabs = tabs.filter((tab) => {
    return !(tab.id === EProfileTabName.WITHDRAWAL && !isCurrentUser.value);
  });

  return filteredTabs.reduce((acc, tab) => ({ ...acc, [tab.id]: tab }), {} as Record<IProfileTab['id'], IProfileTab>);
});

export const battlesFilters = [
  {
    id: ProfileBattlesFilter.ALL,
    localeKey: 'profile.battles.filters.all',
    value: 0,
  },
  {
    id: ProfileBattlesFilter.TWO,
    localeKey: 'profile.battles.filters.two',
    value: 2,
  },
  {
    id: ProfileBattlesFilter.THREE,
    localeKey: 'profile.battles.filters.three',
    value: 3,
  },
  {
    id: ProfileBattlesFilter.FOUR,
    localeKey: 'profile.battles.filters.four',
    value: 4,
  },
];

export const battlesFiltersMap = battlesFilters.reduce(
  (acc, filter) => ({ ...acc, [filter.id]: filter }),
  {} as Record<IProfileBattlesFilter['id'], IProfileBattlesFilter>,
);
