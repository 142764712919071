<script setup lang="ts">
import {
  UpgradesListButtonColorPreset,
  UpgradesListButtonEmptyColorPreset,
  UpgradesListButtonEmptyFormPreset,
  UpgradesListButtonFormPreset,
  UpgradesListSkeletonButtonProps,
} from './UpradesList.data';
import type { IUpgradesListProps, IUpgradesListEmits } from './UpgradesList.types';
import type { IUpgradeItem, TExtendedInventory } from '~/repository/modules/profile/types/upgrades';
import { ProfileUpgradesEvents } from '~/repository/amplitude/events/profile/profile-upgrades';
import { useProfileUpgradeStore } from '~/features/profile/store/profileUpgrades/profileUpgrades.store';

const props = withDefaults(defineProps<IUpgradesListProps>(), {
  items: (): IUpgradeItem[] => [],
});

const { items, isLastPage, isPending } = toRefs(props);

const emits = defineEmits<IUpgradesListEmits>();

const isEmptyInventory = computed(() => !items.value.length && !isPending.value);

const upgradesStore = useProfileUpgradeStore();
const { upgrades } = storeToRefs(upgradesStore);

onUnmounted(() => {
  upgradesStore.pagination.currentPage = 1;
  upgrades.value = [];
});
</script>

<template>
  <div class="upgrades-list">
    <template v-if="!isEmptyInventory">
      <div class="upgrades-list__items">
        <ProfileUpgradesItem
          v-for="upgrade in items"
          :key="upgrade.index"
          class="upgrades-list__item"
          :items="upgrade.items ?? []"
          :index="upgrade.index ?? ''"
          :percent="upgrade.percent ?? 0"
          :prize="upgrade.prize ?? ({} as TExtendedInventory)"
          :win="upgrade.win"
        />
      </div>
    </template>
    <template v-else-if="isPending">
      <div class="upgrades-list__items">
        <ProfileUpgradesItemSkeleton v-for="i in 4" :key="i" />
      </div>
    </template>
    <template v-if="isEmptyInventory && !isPending">
      <div class="upgrades-list__empty">
        <SvgoUpgradeIconNoBg :width="120" :height="120" class="upgrades-list__empty-icon" />
        <div class="upgrades-list__empty-info">
          <div class="upgrades-list__empty-info-label">{{ $t('profile.upgrades.emptyState.title') }}</div>
          <div class="upgrades-list__empty-info-text">{{ $t('profile.upgrades.emptyState.text') }}</div>
        </div>
        <SharedButtonNew
          class="upgrades-list__empty-btn"
          :form="UpgradesListButtonEmptyFormPreset"
          :color="UpgradesListButtonEmptyColorPreset"
          :link-props="{
            to: useLinks().UPGRADES,
            target: '_blank',
          }"
          @click="ProfileUpgradesEvents.createUpgradeClicked"
        >
          {{ $t('profile.upgrades.emptyState.buttonText') }}
        </SharedButtonNew>
      </div>
    </template>
    <SharedButtonNew
      v-if="!isLastPage && !isPending && !isEmptyInventory"
      class="upgrades-list__load"
      :form="UpgradesListButtonFormPreset"
      :color="UpgradesListButtonColorPreset"
      @click="emits('load')"
    >
      {{ $t('loadMore') }}
    </SharedButtonNew>
    <SkeletonBaseForeground
      v-else-if="isPending"
      class="upgrades-list__load"
      v-bind="UpgradesListSkeletonButtonProps"
    />
  </div>
</template>

<style scoped lang="scss" src="./UpgradesList.scss" />
