<script lang="ts" setup>
import InventoryExchangePopup from '../../widgets/InventoryExchangePopup/InventoryExchangePopup.vue';
import type { IExchangeControllerProps } from './InventoryExchangeController.types';

withDefaults(defineProps<IExchangeControllerProps>(), {
  modelValue: false,
});

const model = defineModel<boolean>('modelValue', {
  required: true,
});
</script>

<template>
  <SharedModal v-model="model" class="inventory-exchange-controller sharedModal__overlay_padding_medium">
    <InventoryExchangePopup />
  </SharedModal>
</template>
