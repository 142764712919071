<script setup lang="ts">
import InventoryExchangeItem from '~/features/profile/components/InventoryExchangeItem/InventoryExchangeItem.vue';
import { useInventoryStore } from '~/store/inventory/inventory';
import type { IInventory } from '~/repository/modules/inventory/inventory.types';

const inventoryStore = useInventoryStore();
const { changeItemList, currentTradeItem, currentExchangeTradeItem } = storeToRefs(inventoryStore);

const handleExchangeItemSelect = (item: IInventory) => {
  if (currentExchangeTradeItem.value) {
    currentExchangeTradeItem.value.isSelected = false;
  }

  currentExchangeTradeItem.value = item;
  currentExchangeTradeItem.value.isSelected = true;
};
</script>

<template>
  <div class="inventory-exchange-popup-list">
    <div class="inventory-exchange-popup-list__text">
      {{ $t('profile.inventoryExchange.popupListText') }}
    </div>
    <div class="inventory-exchange-popup-list__items">
      <InventoryExchangeItem
        v-for="itemToExchange in changeItemList"
        :key="itemToExchange.id"
        :item-to-exchange="currentTradeItem"
        :inventory="itemToExchange"
        :is-selected="itemToExchange.isSelected"
        @selected-toggle="handleExchangeItemSelect"
      />
    </div>
  </div>
</template>

<style scoped lang="scss" src="./InventoryExchangePopupList.scss" />
