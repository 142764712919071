<template>
  <ProfileStatUpgrades v-if="isLoaded" v-bind="props" />
  <ProfileStatBaseSkeleton v-else :icons-count="4" />
</template>

<script setup lang="ts">
import type { IStatUpgradesSkeletonProps } from './StatUpgrades.types';

const props = defineProps<IStatUpgradesSkeletonProps>();
</script>
