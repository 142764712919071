<script setup lang="ts">
import { useUserStore } from '~/store/user/user.store';

const userStore = useUserStore();
const { isUserLoaded, currentUser, isCurrentUser } = storeToRefs(userStore);
</script>

<template>
  <SkeletonComponentLoader>
    <section v-if="!isUserLoaded || (!currentUser?.userId && !isCurrentUser)" class="user-info-controller-skeleton">
      <SkeletonBaseBackground class="user-info-controller-skeleton__bg">
        <div class="user-info-controller-skeleton__top">
          <SkeletonBaseForeground class="user-info-controller-skeleton__top-avatar" />
          <div class="user-info-controller-skeleton__top-info">
            <SkeletonBaseForeground
              class="user-info-controller-skeleton__foreground user-info-controller-skeleton__foreground--smaller"
            />
            <SkeletonBaseForeground class="user-info-controller-skeleton__foreground" />
          </div>
        </div>
        <div class="user-info-controller-skeleton__bottom">
          <SkeletonBaseForeground
            class="user-info-controller-skeleton__foreground user-info-controller-skeleton__foreground--smaller"
          />
          <SkeletonBaseForeground class="user-info-controller-skeleton__bottom-button" />
        </div>
      </SkeletonBaseBackground>
    </section>
    <ProfileUserInfoController v-else />
  </SkeletonComponentLoader>
</template>

<style scoped lang="scss" src="./UserInfoController.skeleton.scss" />
