<template>
  <div :class="containerClasses">
    <div class="user-statistics_content">
      <h1 :class="headerClasses" @click="toggleCollapse">
        <SvgoProfileStatisticsChevron class="user-statistics_header_chevron" :class="chevronClasses" />
        {{ header }}
      </h1>
      <div class="user-statistics_badges-container" :class="badgesContainerClasses">
        <slot name="badges" />
        <div v-if="'bestDrop' in $slots" class="user-statistics_dropItemBadge-container">
          <slot name="bestDrop" />
        </div>
      </div>
      <div v-if="!!$slots.footer" :class="footerClasses">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IStatBaseProps } from './StatBase.types';

const slots = useSlots();

defineProps<IStatBaseProps>();

const isCollapsed = ref(false);

const containerClasses = computed(() => [
  'user-statistics_container',
  { 'user-statistics_container_collapsed': isCollapsed.value },
]);

const badgesContainerClasses = computed(() => ({
  'user-statistics_badges-container_collapsed': isCollapsed.value,
  'user-statistics_badges-container_hasDropItem': 'bestDrop' in slots,
}));

const headerClasses = computed(() => [
  'user-statistics_header',
  { 'user-statistics_header_hasDropItem': 'bestDrop' in slots ?? {} },
]);

const chevronClasses = computed(() => ({ 'user-statistics_header_chevron': isCollapsed.value }));

const footerClasses = computed(() => [
  'user-statistics_footer',
  {
    'user-statistics_footer_collapsed': isCollapsed.value,
  },
]);

function toggleCollapse() {
  isCollapsed.value = !isCollapsed.value;
}
</script>

<style scoped src="./StatBase.scss" lang="scss"></style>
