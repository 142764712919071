<template>
  <div class="inventory-wrapper">
    <ProfileStatGeneralSkeleton v-bind="statGeneralStore.data" :is-loaded="statGeneralStore.isLoaded" />
    <InventoryList @selected-toggle="(item: IInventory) => handlerSelectedInventory(item)" />
    <ProfileInventoryExchangeController v-model="isExchangePopupVisible" />
  </div>
</template>

<script setup lang="ts">
import InventoryList from '~/features/profile/components/InventoryList/InventoryList.vue';
import { useStatGeneralStore } from '~/features/profile/store/statGeneral/StatGeneral.store';
import { useInventoryStore } from '~/store/inventory/inventory';
import type { IInventory } from '~/repository/modules/inventory/inventory.types';
import { useUserStore } from '~/store/user/user.store';

const statGeneralStore = useStatGeneralStore();
const inventoryStore = useInventoryStore();
const userStore = useUserStore();

const { selectedInventory, isExchangePopupVisible } = storeToRefs(inventoryStore);

const handlerSelectedInventory = (item: IInventory) => {
  const el = selectedInventory.value.find((el) => el.id === item.id);

  if (el) {
    inventoryStore.toggleSelectItemState(el);
  }
};

watch(
  () => userStore.isUserLoaded,
  async (isLoaded) => {
    if (isLoaded) {
      await statGeneralStore.fetch();
    }
  },
);
</script>

<style src="./ProfileInventoryTabController.style.scss" lang="scss" scoped></style>
