<script setup lang="ts">
import type { IBaseForegroundProps } from '~/features/skeleton/components/BaseForeground/BaseForeground.types';

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const containerProps = computed<IBaseForegroundProps>(() => ({
  borderRadius: changeXL(8, 6),
  width: 50,
  height: 50,
}));

const mobileContainerProps: IBaseForegroundProps = {
  borderRadius: 4,
  width: 50,
  height: 50,
};
</script>

<template>
  <SkeletonComponentLoader class="inventory-item-skeleton">
    <section>
      <SkeletonBaseForeground class="inventory-item-skeleton__drop" v-bind="containerProps" />
      <div class="inventory-item-skeleton__mobile">
        <SkeletonBaseForeground class="inventory-item-skeleton__mobile-price" v-bind="mobileContainerProps" />
        <SkeletonBaseForeground
          class="inventory-item-skeleton__mobile-label inventory-item-skeleton__mobile-label--big"
          v-bind="mobileContainerProps"
        />
        <SkeletonBaseForeground class="inventory-item-skeleton__mobile-label" v-bind="mobileContainerProps" />
      </div>
    </section>
  </SkeletonComponentLoader>
</template>

<style scoped lang="scss" src="./InventoryItem.skeleton.scss" />
