import type {
  ISharedButtonNewColorPreset,
  ISharedButtonNewFormPreset,
  ISharedButtonTextPreset,
} from '~/types/SharedButtonNew.types';
import {
  defaultButtonColorPreset,
  defaultMediumButtonTextPreset,
  defaultMiddleButtonFormPreset,
} from '~/components/SharedButtonNew/SharedButtonNew.data';
import { Colors } from '~/constants/colors.constants';

const changeMD = GlobalUtils.Media.changeByMedia('md');

export const InventoryActionsButtonFormPreset = computed<ISharedButtonNewFormPreset>(() => ({
  ...defaultMiddleButtonFormPreset,
  height: changeMD('40px', '32px'),
  minWidth: changeMD('219px', '132px'),
  radius: 'var(--border-radius-xs)',
  width: changeMD('219px', '132px'),
}));

export const InventoryActionsButtonSmall = computed<ISharedButtonNewFormPreset>(() => ({
  ...defaultMiddleButtonFormPreset,
  height: changeMD('40px', '32px'),
  minWidth: '100px',
  radius: 'var(--border-radius-xs)',
  width: '100px',
}));

export const InventoryActionsButtonColorPreset = computed<ISharedButtonNewColorPreset>(() => ({
  ...defaultButtonColorPreset,
  color: 'var(--neutral-white-15)',
}));

export const InventoryActionsSendButtonPreset = computed<ISharedButtonNewColorPreset>(() => ({
  color: Colors.MAIN.GOLD,
  colorBorder: Colors.MAIN.GOLD,
  colorHovered: Colors.NEUTRAL.WHITE,
  colorInactive: Colors.MAIN.GOLD,
}));

export const InventoryActionsButtonTextPreset = computed<ISharedButtonTextPreset>(() => ({
  ...defaultMediumButtonTextPreset,
  size: changeMD('var(--font-size-m)', 'var(--font-size-s)'),
}));
