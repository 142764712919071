import { useUserStore } from '~/store/user/user.store';
import { usePagination } from '~/composables/usePagination';
import type {
  IUpgradeItem,
  TGetProfileUpgradesRequestDto,
  TGetProfileUpgradesResponseDto,
} from '~/repository/modules/profile/types/upgrades';

export const useProfileUpgradeStore = defineStore('profile/upgrades', () => {
  const { $api } = useNuxtApp();
  const userStore = useUserStore();

  const route = useRoute();

  const { isUserLoaded } = storeToRefs(userStore);

  const upgrades = ref<IUpgradeItem[]>([]);

  const total = ref(0);
  const pageSize = ref(10);
  const pagination = usePagination({
    onLoadMore: async () => {
      await refreshProfileUpgrades();
    },
    page: 1,
    pageSize: pageSize.value,
    total,
  });

  const fetchUpgrades = async (params: TGetProfileUpgradesRequestDto): Promise<TGetProfileUpgradesResponseDto> => {
    const response = await GlobalUtils.Api.handleRequest(async () => {
      return await $api.profile.getUpgrades(params);
    });

    return response ?? ({} as TGetProfileUpgradesResponseDto);
  };

  const {
    data: profileUpgrades,
    pending: profileUpgradesPending,
    refresh: refreshProfileUpgrades,
  } = useLazyAsyncData<TGetProfileUpgradesResponseDto>(
    'get-upgrades',
    async () => {
      const params: TGetProfileUpgradesRequestDto = {
        page: pagination.currentPage.value,
        perPage: pagination.currentPageSize.value,
        userId: Number(route.params.userId),
      };

      const response = await fetchUpgrades(params);
      if (response.data.items) {
        total.value = response.data.totalItem;
        upgrades.value.push(...response.data.items);
      }

      return response;
    },
    {
      default: () => ({}) as TGetProfileUpgradesResponseDto,
      immediate: false,
    },
  );

  watch(isUserLoaded, async (isLoaded) => {
    if (isLoaded) {
      await refreshProfileUpgrades();
    }
  });

  return {
    profileUpgrades,
    profileUpgradesPending,
    pagination,
    refreshProfileUpgrades,
    upgrades,
  };
});
