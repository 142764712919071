import type { CSSProperties } from 'vue';
import type { IUser } from '~/store/user/user.types';

export interface IUserAvatarContainerProps {
  currentUser?: IUser;
}

export interface IUserAvatarCircle {
  class: string;
  show: boolean;
  style?: CSSProperties;
}

export interface IUserAvatarContainerEmits {
  (event: 'openExpPopup'): void;
}

export enum EUserAvatarContainerStatus {
  IDLE = 'idle',
  GENERATING = 'generating_level',
  DONE = 'done',
}
