import type { IStatGeneralState } from './StatGeneral.types';
import type { TCurrencyType } from '~/types/Shared.types';
import type { Colors } from '~/constants/colors.constants';
import type { IGetSatDashboardParams } from '~/repository/modules/profile/types/statistics';

export const useStatGeneralStore = defineStore('profile/statGeneral', () => {
  const { $api } = useNuxtApp();
  const route = useRoute();

  const isLoaded = ref(false);
  const data = ref({} as IStatGeneralState);

  function fetch() {
    return GlobalUtils.Api.handleRequest(async () => {
      isLoaded.value = false;

      const params: IGetSatDashboardParams = {
        userId: Number(route.params.userId),
      };

      const resp = await $api.profile.getStatDashboard(params);

      if (resp.status) {
        const bestDropCurrency = resp.data.bestDrop.currency as TCurrencyType;
        const bestDropRarity = resp.data.bestDrop.rare.toUpperCase() as keyof typeof Colors.RARITY;

        data.value = {
          battlesCount: formatNumber(resp.data.battlesCount, { dashIfZero: true }),
          bestDropCurrency,
          bestDropImage: resp.data.bestDrop.img,
          bestDropName: resp.data.bestDrop.name,
          bestDropPrice: Number(resp.data.bestDrop.price).toFixed(2),
          bestDropRarity,
          bestDropType: resp.data.bestDrop.type,
          inventoryCount: formatNumber(resp.data.inventoryCount, { dashIfZero: true }),
          sendItemsCount: formatNumber(resp.data.sendItemsCount, { dashIfZero: true }),
          upgradesCount: formatNumber(resp.data.upgradesCount, { dashIfZero: true }),
        };

        isLoaded.value = true;
      }
    });
  }

  return { fetch, isLoaded, data };
});
