/* eslint-disable @typescript-eslint/naming-convention */

export enum EProfileTabsEvents {
  TAB_CLICKED = 'Profile - Tab Clicked',
  LOAD_MORE_CLICKED = 'Profile - Load More Clicked',
}

export enum EProfileTabName {
  INVENTORY = 'Inventory',
  BATTLES = 'Battles',
  UPGRADES = 'Upgrades',
  WITHDRAWAL = 'Withdrawal',
}

export interface IBaseTabEventClicked {
  'Tab Name': EProfileTabName;
}
