import type { ISharedButtonNewColorPreset, ISharedButtonNewFormPreset } from '~/types/SharedButtonNew.types';
import {
  defaultButtonColorPreset,
  defaultMiddleButtonFormPreset,
} from '~/components/SharedButtonNew/SharedButtonNew.data';
import type { IBaseForegroundProps } from '~/features/skeleton/components/BaseForeground/BaseForeground.types';

const changeMD = GlobalUtils.Media.changeByMedia('md');

export const UpgradesListButtonFormPreset = computed<ISharedButtonNewFormPreset>(() => ({
  ...defaultMiddleButtonFormPreset,
  height: changeMD('40px', '32px'),
  minWidth: '200px',
  radius: 'var(--border-radius-xs)',
  width: '200px',
}));

export const UpgradesListButtonColorPreset: ISharedButtonNewColorPreset = {
  ...defaultButtonColorPreset,
  color: 'var(--neutral-white-15)',
};

export const UpgradesListSkeletonButtonProps = <IBaseForegroundProps>{
  borderRadius: 8,
  width: 200,
  height: 40,
};

export const UpgradesListButtonEmptyFormPreset = computed<ISharedButtonNewFormPreset>(() => ({
  ...defaultMiddleButtonFormPreset,
  height: changeMD('50px', '40px'),
  minWidth: changeMD('179px', '163px'),
  radius: changeMD('var(--border-radius-xs)', 'var(--border-radius-ss)'),
  width: changeMD('179px', '163px'),
}));

export const UpgradesListButtonEmptyColorPreset: ISharedButtonNewColorPreset = {
  ...defaultButtonColorPreset,
  color: 'var(--main-purple-200)',
  colorHovered: 'var(--main-purple-100)',
};
