<template>
  <div :class="['user-avatar', containerClasses]">
    <span class="user-avatar__level" :style="{ background: styleVariables.background }">
      {{ Number(user?.userLevel) < 0 ? 0 : user?.userLevel }}
      <SvgoQuestionIcon class="user-avatar__level-icon" @click="emits('openExpPopup')" />
    </span>
    <div v-if="!isBot" class="user-avatar__experience">
      {{ userExperience }}
      <SvgoInfo v-if="wasGeneratedLevel" :width="12" :height="12" @click="emits('openExpPopup')" />
    </div>
    <div v-if="!wasGeneratedLevel && isCurrentUser" class="user-avatar__content" @click="generateLevelHandler">
      <div :class="['user-avatar__label', labelClasses]">
        {{ labelText }}
      </div>
      <div
        v-for="circle in circles"
        v-show="circle.show"
        :key="circle.class"
        :class="['user-avatar__circle', circle.class]"
        :style="circle.style"
      />
      <NuxtImg
        v-show="status === EUserAvatarContainerStatus.DONE"
        class="user-avatar__image user-avatar__image--generating"
        :src="avatarSrc"
        provider="localProvider"
      />
    </div>
    <div v-else class="user-avatar__content" :style="{ border: styleVariables.border }">
      <NuxtImg
        class="user-avatar__image"
        :src="avatarSrc"
        provider="localProvider"
        :style="{ boxShadow: styleVariables.boxShadow }"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { CSSProperties } from 'vue';
import type {
  IUserAvatarCircle,
  IUserAvatarContainerProps,
  IUserAvatarContainerEmits,
} from './UserAvatarContainer.types';
import { EUserAvatarContainerStatus } from './UserAvatarContainer.types';
import BotImg from '~/assets/images/Battles/bot.png';
import { getLevelVariable, GRADIENT_LEVELS } from '~/components/HeaderProfile/HeaderProfile.data';
import { useGenerateUserLevelStore } from '~/store/generateUserLevel/generateUserLevel.store';
import { useUserStore } from '~/store/user/user.store';
import { useAlertStore } from '~/store/alert/alert.store';

const props = defineProps<IUserAvatarContainerProps>();
const { currentUser } = toRefs(props);
const emits = defineEmits<IUserAvatarContainerEmits>();

const { t } = useI18n();

const userStore = useUserStore();
const generateLevelStore = useGenerateUserLevelStore();
const alertStore = useAlertStore();

const { user, isCurrentUser } = storeToRefs(userStore);

const status = ref<EUserAvatarContainerStatus>(
  isCurrentUser.value
    ? currentUser.value?.wasGeneratedLevel
      ? EUserAvatarContainerStatus.DONE
      : EUserAvatarContainerStatus.IDLE
    : EUserAvatarContainerStatus.DONE,
);

const isBot = computed(() => !!currentUser.value?.isBot);
const wasGeneratedLevel = computed(() => !!currentUser.value?.wasGeneratedLevel);
const avatarSrc = computed(() => currentUser.value?.avatar || BotImg);

const statusFlow: Record<EUserAvatarContainerStatus, EUserAvatarContainerStatus> = {
  [EUserAvatarContainerStatus.IDLE]: EUserAvatarContainerStatus.GENERATING,
  [EUserAvatarContainerStatus.GENERATING]: EUserAvatarContainerStatus.DONE,
  [EUserAvatarContainerStatus.DONE]: EUserAvatarContainerStatus.IDLE,
};

// function stateMachine() {
//   status.value = statusFlow[status.value];
// }

async function generateLevelHandler() {
  status.value = statusFlow[EUserAvatarContainerStatus.GENERATING];

  const result = await generateLevelStore.generateLevel();

  if (result) {
    status.value = statusFlow[EUserAvatarContainerStatus.DONE];
  } else {
    status.value = statusFlow[EUserAvatarContainerStatus.IDLE];
  }

  alertStore.showError({
    message: t('mainPage.dailyCases.generationError.message'),
    title: t('mainPage.dailyCases.generationError.title'),
  });
}

const labelText = computed(() => {
  const statusTexts = {
    [EUserAvatarContainerStatus.IDLE]: 'Сгенерировать уровень',
    [EUserAvatarContainerStatus.GENERATING]: 'Генерируем',
    [EUserAvatarContainerStatus.DONE]: 'Готово',
  };
  return statusTexts[status.value];
});

const containerClasses = computed(() => ({
  'user-avatar--generated': currentUser.value?.wasGeneratedLevel,
  'user-avatar--idle': status.value === EUserAvatarContainerStatus.IDLE,
  'user-avatar--generating': status.value === EUserAvatarContainerStatus.GENERATING,
  'user-avatar--done': status.value === EUserAvatarContainerStatus.DONE && currentUser.value?.wasGeneratedLevel,
}));

const labelClasses = computed(() => ({
  'user-avatar__label--active': status.value !== EUserAvatarContainerStatus.IDLE,
  'fade-out': status.value === EUserAvatarContainerStatus.DONE,
}));

const pulsingCircleStyle = computed<CSSProperties>(() => ({
  opacity: status.value === EUserAvatarContainerStatus.GENERATING ? 0.25 : 0,
  background: 'var(--main-purple-100)',
}));

const commonCircleStyle = {
  style: pulsingCircleStyle.value,
  show: true,
};

const circles = computed<IUserAvatarCircle[]>(() => [
  { class: 'user-avatar__circle--rotating-clockwise', show: status.value === EUserAvatarContainerStatus.IDLE },
  { class: 'user-avatar__circle--rotating-counterclockwise', show: status.value === EUserAvatarContainerStatus.IDLE },
  { class: 'user-avatar__circle--rainbow', show: status.value === EUserAvatarContainerStatus.GENERATING },
  { class: 'user-avatar__circle--progress', show: status.value === EUserAvatarContainerStatus.DONE },
  { class: 'user-avatar__circle--background', show: true },
  { ...commonCircleStyle, class: 'user-avatar__circle--pulsing-big' },
  { ...commonCircleStyle, class: 'user-avatar__circle--pulsing-small' },
]);

const styleVariables = computed<CSSProperties>(() => {
  const isGradient = Number(currentUser.value?.userLevel) >= GRADIENT_LEVELS;
  const levelVariable = currentUser.value?.wasGeneratedLevel
    ? getLevelVariable(Number(props.currentUser?.userLevel))
    : 'var(--main-purple-600)';
  const levelShadow = isGradient ? '#dc68d0' : levelVariable;

  return {
    border: `4px solid ${levelVariable}`,
    boxShadow: `0px 0px 40px 0px ${levelShadow}`,
    background: levelVariable,
  };
});

const userExperience = computed(() => {
  return currentUser.value?.wasGeneratedLevel
    ? ` ${currentUser.value?.currentExp} / ${currentUser.value?.expToNextLevel} XP`
    : '0 / 0 XP';
});
</script>

<style scoped lang="scss" src="./UserAvatarContainer.scss" />
